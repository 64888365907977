import { constants as c } from "../constants";
const initialState = {

  info: {
    status: c.NONE,
  },

  branch_list: {
    data: [],
    status: c.NONE,
  },

};
export function branch(state = initialState, action) {
  switch (action.type) {

      case c.GET_ALL_BRANCH_SUCCESS:
        return {
          ...state,
          branch_list: {
            data : action.data,
            status: c.SUCCESS,
          },
        };



      case c.RESET_BRANCH_LIST_STATUS:
        return {
          ...state,
          branch_list: {
            data : [],
            status: c.NONE,
          },
        };
  


  
      return {
        ...state,
        info: {
          status: c.FAILURE,
        },
      };
    default:
      return state;
  }
}
