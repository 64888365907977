import React, { Component, useState } from "react";
import { toast } from "react-toastify";

export default function PermissionChecker({ decent, child }) {
  return (
    <div style={{ position: "relative" }}>
      {decent === false ? (
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            //backgroundColor: "rgba(128, 128, 128, 0.5)",
            width: "100%",
            height: "100%",
          }}
          onClick={(e) => {
            e.stopPropagation();
            toast.error("Bạn không có quyền truy cập");
          }}
        ></div>
      ) : (
        <></>
      )}
      <div style={{ zIndex: 0 }}>{child}</div>
    </div>
  );
}
