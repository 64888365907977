export default function DoubleArrowLeft({ className, ...rest }) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...rest}
    >
      <circle cx="6" cy="6" r="6" fill="#32C342" />
      <path
        d="M6.00031 8.33334C5.9341 8.33373 5.86928 8.31439 5.81411 8.27779C5.75893 8.24119 5.71591 8.18898 5.69052 8.12784C5.66513 8.06669 5.65853 7.99937 5.67155 7.93445C5.68457 7.86953 5.71663 7.80997 5.76364 7.76334L7.53031 6.00001L5.76364 4.23668C5.70904 4.17291 5.6805 4.09088 5.68374 4.00699C5.68698 3.9231 5.72176 3.84352 5.78112 3.78415C5.84049 3.72479 5.92007 3.69001 6.00396 3.68677C6.08785 3.68353 6.16988 3.71207 6.23364 3.76668L8.23364 5.76668C8.29573 5.82913 8.33058 5.91361 8.33058 6.00168C8.33058 6.08974 8.29573 6.17422 8.23364 6.23668L6.23364 8.23668C6.17156 8.29826 6.08776 8.33297 6.00031 8.33334Z"
        fill="black"
      />
      <path
        d="M4.00031 8.33331C3.9341 8.3337 3.86928 8.31436 3.81411 8.27776C3.75893 8.24116 3.71591 8.18896 3.69052 8.12781C3.66513 8.06666 3.65853 7.99934 3.67155 7.93442C3.68457 7.8695 3.71663 7.80994 3.76364 7.76331L5.53031 5.99998L3.76364 4.23665C3.70088 4.17388 3.66561 4.08875 3.66561 3.99998C3.66561 3.91121 3.70088 3.82608 3.76364 3.76331C3.82641 3.70055 3.91154 3.66528 4.00031 3.66528C4.08908 3.66528 4.17421 3.70055 4.23698 3.76331L6.23698 5.76331C6.29906 5.82577 6.33391 5.91025 6.33391 5.99831C6.33391 6.08638 6.29906 6.17086 6.23698 6.23331L4.23698 8.23331C4.20611 8.26481 4.16929 8.28987 4.12867 8.30703C4.08804 8.3242 4.04441 8.33313 4.00031 8.33331Z"
        fill="black"
      />
    </svg>
  );
}
