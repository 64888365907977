import moment from "moment";

export const handleShowtimeRoom = (time) => {
  const now = moment.utc(); // chuyển về UTC
  const date = moment.utc(time); // parse time sang UTC

  if (now.diff(date, "days") < 1) {
    return date.format("HH:mm");
  } else if (now.diff(date, "days") < 7) {
    return `${now.diff(date, "days")} ngày`;
  } else {
    return date.format("DD/MM");
  }
};

export const handleTimeToMinutes = (time, type) => {
  const timeString = moment.utc(time).format("DD/MM/YYYY HH:mm:ss").toString();
  const nowString = moment(Date().now).format("DD/MM/YYYY HH:mm:ss").toString();
  const dateTime = moment(timeString, "DD/MM/YYYY HH:mm:ss");
  const dateNow = moment(nowString, "DD/MM/YYYY HH:mm:ss");
  const diff = moment(dateNow).diff(moment(dateTime));
  const diffInMinutes = moment.duration(diff).asMinutes();
  const diffInHours = moment.duration(diff).asHours();
  const diffInDays = moment.duration(diff).asDays();
  const diffInYears = moment.duration(diff).asYears();
  if (type === "hour") return Math.ceil(diffInHours) || 1;
  if (type === "day") return Math.ceil(diffInDays) || 1;
  if (type === "year") return Math.ceil(diffInYears);
  return Math.ceil(diffInMinutes);
};

export const handleShowTime = (time) => {
  if (handleTimeToMinutes(time) < 1) return "Vài giây";

  if (handleTimeToMinutes(time) < 59) {
    return `${handleTimeToMinutes(time)} phút`;
  }

  if (handleTimeToMinutes(time, "hour") < 24) {
    return `${handleTimeToMinutes(time, "hour")} giờ`;
  }

  if (handleTimeToMinutes(time, "day") < 7) {
    return `${handleTimeToMinutes(time, "day")} ngày`;
  }

  return `${moment(time).format("DD/MM")}`;
};

export const handleShowTimeMess = (time) => {
  const timeString = moment.utc(time).format("DD/MM/YYYY HH:mm:ss").toString();
  const dateTime = moment(timeString, "DD/MM/YYYY HH:mm:ss");
  if (handleTimeToMinutes(dateTime, "hour") < 24) {
    return `${moment(dateTime).format("HH:mm")}`;
  }
  if (handleTimeToMinutes(dateTime, "year") > 1 ) {
    return `${moment(dateTime).format("DD/MM/YYYY HH:mm")}`;
  }
  return `${moment(dateTime).format("DD/MM HH:mm")}`;
};
