import React, { Component } from "react";

export default function ModalUID(props, {}) {
  var { title, children, method, size, showFooter } = props;

  var action =
    method === "CREATE"
      ? "Tạo"
      : method === "EDIT"
      ? "Lưu"
      : method === "DELETE"
      ? "Xóa"
      : method === "UPDATE"
      ? "Lưu"
      : method === "ACCEPT"
      ? "Đồng ý"
      : "";

  function handleOnSave(e) {
    e.preventDefault();
    props.handleOnSave();
  }

  return (
    <div class="modal" id="modalUID">
      <div class={`modal-dialog modal-${size}`}>
        <div class="modal-content">
          <div class="modal-header">
            <h4 style={{ color: "white" }}>{title}</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form onSubmit={handleOnSave}>
            <div class="modal-body" style={{ maxHeight: "550px" }}>
              {children}
            </div>

            {showFooter !== false && (
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-default"
                  data-dismiss="modal"
                >
                  Đóng
                </button>
                {action !== "" && (
                  <button
                    type="button"
                    onClick={handleOnSave}
                    class="btn btn-info"
                  >
                    {action}
                  </button>
                )}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
