import { constants as c } from "../constants";
const initialState = {

   data: {
      status: c.NONE,
   },
};
export function banner(state = initialState, action) {
   switch (action.type) {


      case c.RESET_BANNER_STATUS:
         return {
            ...state,
            data: {
               status: c.NONE,
            },
         };

      case c.GET_BANNER_SUCCESS:
         return {
            ...state,
            data: {
               ...action.data,
               status: c.SUCCESS,
            },
         };

         return {
            ...state,
            info: {
               status: c.FAILURE,
            },
         };
      default:
         return state;
   }
}
