export default function DoubleArrowRight({ className, ...rest }) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...rest}
    >
      <circle cx="6" cy="6" r="6" fill="#32C342" />
      <path
        d="M5.99961 8.33334C5.95574 8.3336 5.91226 8.32519 5.87164 8.3086C5.83103 8.29201 5.79409 8.26757 5.76295 8.23668L3.76295 6.23668C3.70086 6.17422 3.66602 6.08974 3.66602 6.00168C3.66602 5.91361 3.70086 5.82913 3.76295 5.76668L5.76295 3.76668C5.82671 3.71207 5.90874 3.68353 5.99263 3.68677C6.07652 3.69001 6.1561 3.72479 6.21547 3.78415C6.27483 3.84352 6.30961 3.9231 6.31285 4.00699C6.31609 4.09088 6.28756 4.17291 6.23295 4.23668L4.46961 6.00001L6.23295 7.76334C6.27975 7.80977 6.31174 7.86903 6.32487 7.93363C6.338 7.99824 6.33168 8.06528 6.3067 8.12629C6.28173 8.1873 6.23922 8.23954 6.18456 8.27639C6.1299 8.31325 6.06554 8.33307 5.99961 8.33334Z"
        fill="black"
      />
      <path
        d="M7.99961 8.33334C7.95574 8.3336 7.91226 8.32519 7.87164 8.3086C7.83103 8.29201 7.79409 8.26757 7.76295 8.23668L5.76295 6.23668C5.70086 6.17422 5.66602 6.08974 5.66602 6.00168C5.66602 5.91361 5.70086 5.82913 5.76295 5.76668L7.76295 3.76668C7.82671 3.71207 7.90874 3.68353 7.99263 3.68677C8.07652 3.69001 8.1561 3.72479 8.21547 3.78415C8.27483 3.84352 8.30961 3.9231 8.31285 4.00699C8.31609 4.09088 8.28756 4.17291 8.23295 4.23668L6.46961 6.00001L8.23295 7.76334C8.27975 7.80977 8.31174 7.86903 8.32487 7.93363C8.338 7.99824 8.33168 8.06528 8.3067 8.12629C8.28173 8.1873 8.23922 8.23954 8.18456 8.27639C8.1299 8.31325 8.06554 8.33307 7.99961 8.33334Z"
        fill="black"
      />
    </svg>
  );
}
