import { combineReducers } from "redux";
import { user } from "./user";
import { app } from "./app";
import { reportNumber } from "./reportNumber";
import { news } from "./news";
import { staff } from "./staff";
import { notifications } from "./notification";
import { newsComment } from "./newsComment";
import { lessonComment } from "./lessonComment";
import { course } from "./course";
import { lesson } from "./lesson";
import { quiz } from "./quiz";
import { study } from "./study";
import { scoreboard } from "./scoreboard";
import { report } from "./report";
import { decentralization } from "./decentralization";
import { banner } from "./banner";
import { branch } from "./branch";
import { evaluation } from "./evaluation";
import { evaluationQuestion } from "./evaluationQuestion";
import { courseDepartment } from "./courseDepartment";
import { feedback } from "./feedback";
import { area } from "./area";
import { notiNavi } from "./notiNavi";
import { chat } from "./chat";
import { chatAD } from "./chatAD";

const rootReducer = combineReducers({
   staff,
   user,
   app,
   news,
   course,
   lesson,
   quiz,
   notifications,
   study,
   scoreboard,
   newsComment,
   lessonComment,
   report,
   reportNumber,
   decentralization,
   banner,
   branch,
   evaluation,
   evaluationQuestion,
   courseDepartment,
   feedback,
   area,
   notiNavi,
   chat,
   chatAD
});

export default rootReducer;
