import { constants as c } from "../constants";
import { branchServices as s } from "../services/branchServices";

function messageInfo(res, showMessage = true) {
  //showMessage này chỉ dùng nếu check phân quyền, chuyển sang false để check
  if (showMessage !== true) {
    if (res.msg_code === "NOT_AUTHORIZATION") {
      window.location.href = `/${res.code}?code=${res.code}&msg=${res.msg}`;
      return;
    }
    return {
      type: c.UID_FAILURE,
      info: {
        status: res.success,
        msg: res.msg,
      },
    };
  } else {
    if (res.msg_code === "NOT_AUTHORIZATION") {
      window.location.href = `/${res.code}?code=${res.code}&msg=${res.msg}`;
      return;
    }
    return {
      type: c.UID_SUCCESS,
      info: {
        status: res.success,
        msg: res.msg,
      },
    };
  }
}

function getAllBranchs(queryString) {
  return (dispatch) => {
    s.getAllBranchs(queryString).then((res) => {
      dispatch(messageInfo(res, false));
      if (res.success === true) dispatch(success(res.data));
      else dispatch(failure(res.code, res.msg));
    });
  };
  function success(data) {
    return { type: c.GET_ALL_BRANCH_SUCCESS, data };
  }
  function failure(code, message) {
    return { type: c.FAILURE, code, message };
  }
}

function createBranch(info) {
  return (dispatch) => {
    s.createBranch(info).then((res) => {
      dispatch(messageInfo(res));
      if (res.success === true) {
        window.$(".modal").modal("hide");
        dispatch({ type: c.RESET_BRANCH_LIST_STATUS });
      } else dispatch(failure(res.code, res.msg));
    });
  };
  function failure(code, msg) {
    return { type: c.FAILURE, code, msg };
  }
}

function updateBranch(id, info) {
  return (dispatch) => {
    s.updateBranch(id, info)
      .then((res) => {
        dispatch(messageInfo(res));
        if (res.success === true) {
          window.$(".modal").modal("hide");
          dispatch({ type: c.RESET_BRANCH_LIST_STATUS });
        } else dispatch(failure(res.code, res.msg));
      })
      .catch((error) => {});
  };

  function failure(code, msg) {
    return { type: c.FAILURE, code, msg };
  }
}

function deleteBranch(id) {
  return (dispatch) => {
    s.deleteBranch(id).then((res) => {
      dispatch(messageInfo(res));
      if (res.success === true) {
        window.$(".modal").modal("hide");

        dispatch({ type: c.RESET_BRANCH_LIST_STATUS });
      } else dispatch(failure(res.code, res.msg));
    });
  };
  function failure(code, msg) {
    return { type: c.FAILURE, code, msg };
  }
}

export const branchAction = {
  getAllBranchs,
  deleteBranch,
  createBranch,
  updateBranch,
};
