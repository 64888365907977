import { constants as c } from "../constants";
const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));

function getAllReportStaffs(queryString = "") {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(`${c.API_URL}/report_test_staff${queryString}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function getAllReportQuizDeparment(queryString = "") {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/report/quiz_for_deparment${queryString}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function getAllReportQuizsStaff(id = "", queryString = "") {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/report_quizs_staff/${id}${queryString}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function getAllReportEvaluation(id = "", queryString = "") {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/report_manager_staff/${id}${queryString}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function getAllReportEvaluationDetail(info) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
    body: JSON.stringify(info),
  };
  return fetch(`${c.API_URL}/report_detail_staff`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function getTotalOverviewCourse(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(`${c.API_URL}/report_course_staff_chart/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function getTotalOverviewTermquiz(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(`${c.API_URL}/report_quizs_staff_chart/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function getTotalOverviewEvaluation(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(`${c.API_URL}/report_manager_staff_chart/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}
function getAllReportQuizsByCourse(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/report_termquiz_course_staff/${id}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function getAllHistoryQuizs(info) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(`${c.API_URL}/transcripts_history${info}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}
function getReportLessonByCourse(info) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
    body: JSON.stringify(info),
  };
  return fetch(`${c.API_URL}/report_detail_lesson_staff`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}
function getReportDetailCourse(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(`${c.API_URL}/report_detail_course_staff/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}
function getAllReportCourseStaff(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(`${c.API_URL}/report_course_staff/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}
function getAllOverviewQuizs(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(`${c.API_URL}/report_quizs_overview/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function getReportUserWatchsInfo(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(`${c.API_URL}/report_user_watchs/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function exportReportCourse(is_follow_area) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/report_course_follow_area${
      is_follow_area != null ? `?is_follow_area=${is_follow_area}` : ""
    }`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function exportReportTest(is_follow_area) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/report_test_follow_area${
      is_follow_area != null ? `?is_follow_area=${is_follow_area}` : ""
    }`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function exportTestPeri(is_follow_area, month, year) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/test-peri/report-with-follow-excel${
      is_follow_area != null ? `?follow_area=${is_follow_area}` : ""
    }${month != null ? `&month=${month}` : ""}${
      year != null ? `&year=${year}` : ""
    }`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function exportReportCourseStaff(area_id, department_id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/report_course_done_follow_area?area_id=${
      area_id ?? ""
    }&department_id=${department_id ?? ""}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function exportReportTestStaff(area_id, department_id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/report_test_done_follow_area?area_id=${
      area_id ?? ""
    }&department_id=${department_id ?? ""}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function exportReporTestPeriStaff(area_id, department_id, month, year) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/test-peri/report-excel?area_id=${
      area_id ?? ""
    }&department_id=${department_id ?? ""}&month=${month ?? ""}&year=${
      year ?? ""
    }`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function exportReportQc(id, area_ids, from, to) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/qc/qc-evaluate-submit-report?id=${id}&area_ids=${area_ids}&from=${from}&to=${to}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

export const reportServices = {
  getAllReportStaffs,
  getReportUserWatchsInfo,
  getAllReportQuizDeparment,
  getAllReportQuizsStaff,
  getAllReportCourseStaff,
  getReportDetailCourse,
  getReportLessonByCourse,
  getAllHistoryQuizs,
  getAllOverviewQuizs,
  getAllReportEvaluation,
  getAllReportEvaluationDetail,
  getTotalOverviewCourse,
  getTotalOverviewTermquiz,
  getTotalOverviewEvaluation,
  getAllReportQuizsByCourse,
  exportReportCourse,
  exportReportTest,
  exportReportCourseStaff,
  exportReportTestStaff,
  exportReporTestPeriStaff,
  exportTestPeri,
  exportReportQc,
};
