import { message, Upload, Modal } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { BiTrashAlt } from "react-icons/bi";
import "./style.css";
import { toast } from "react-toastify";

const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));

const UploadImageSingle = ({ title, imageInput, onDone }) => {
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      toast.error("You can only upload JPG/PNG file!");
      return false;
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      toast.error("Image must be smaller than 5MB!");
      return false;
    }
    return isJpgOrPng && isLt5M;
  };
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        console.log(url);
        setImageUrl(url);
      });
    }
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
          fontSize: "12px",
        }}
      >
        {title ?? "Upload"}
      </div>
    </div>
  );
  return (
    <>
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        headers={{
          token: tokenInfo,
          "Content-Type": "multipart/form-data",
        }}
        showUploadList={false}
        action="https://crm-api.studyguide.dev/api/upload_v2/files"
        beforeUpload={beforeUpload}
        onPreview={handlePreview}
        onChange={handleChange}
        customRequest={({ file, onSuccess, onError }) => {
          const formData = new FormData();
          formData.append("image", file);
          fetch("https://crm-api.studyguide.dev/api/upload_v2/files", {
            method: "POST",
            headers: {
              token: tokenInfo,
            },
            body: formData,
          })
            .then((response) => response.json())
            .then((data) => {
              console.log(data);
              onSuccess();
              onDone(data?.data?.image_url);
            })
            .catch((error) => {
              onError();
            });
        }}
      >
        {imageInput != null && imageInput != "" ? (
          <div className="image-container">
            <img src={imageInput} alt="avatar" className="hoverable-image" />
            <div className="overlay-image">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                 <AiOutlineEye
                color="white"
                size={25}
                onClick={(e) => {
                  e.stopPropagation();
                  handlePreview({ url: imageInput ?? imageUrl });
                }}
              />
              <BiTrashAlt
                color="white"
                size={25}
                onClick={(e) => {
                  e.stopPropagation();
                  onDone(null);
                }}
              />
              </div>
            </div>
          </div>
        ) : (
          uploadButton
        )}
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
};
export default UploadImageSingle;
