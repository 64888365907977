import { constants as c } from "../constants";
const initialState = {
  messageList: [],
  roomSelected: {},
  roomChatList: [],
  roomChatListByUserId: [],
  allRoomListInfo: {},
  userId: "",
  messageReplySelected: {},
  uniqueIdPresent: "",
  status: c.NONE,
  allMessageInfo: {},
  searchRoomRecentlyList: [],
  searchMemberList: [],
  searchMessageList: [],
  dataTyping: {},
  fileList: [],
  modalSelectUser: {
    isOpen: false,
    type: "",
    data: {},
    loading: false,
  },
  fullscreen: false,
  isHideMainContent: true,
  modalUserInfo: {
    isOpen: false,
    data: {},
  },
};
export function chatAD(state = initialState, action) {
  switch (action.type) {
    // FUNCTION PHÒNG CHAT
    // case c.GET_ROOM_CHAT_SUCCESS_AD:
    //   if (action.data.current_page > 1) {
    //     return {
    //       ...state,
    //       searchRoomRecentlyList: action.data.data,
    //       roomChatList: [...state.roomChatList, ...action.data.data],
    //       allRoomListInfo: action.data,
    //     };
    //   }
    //   if (action.search)
    //     return {
    //       ...state,
    //       searchRoomRecentlyList:
    //         action.data.data.length &&
    //         action.data.data.filter((item) => {
    //           return !item.users.some(
    //             (user) => user.id === state.modalSelectUser?.data
    //           );
    //         }),
    //     };
    //   return {
    //     ...state,
    //     searchRoomRecentlyList: action.data.data,
    //     roomChatList: action.data.data,
    //     allRoomListInfo: action.data,
    //   };

    case c.GET_ROOM_CHAT_BY_USER_ID_AD:
      console.log('action.data: 1', action.data);
      if (action.data.current_page > 1) {
        console.log('action.data: 2', action.data);
        return {
          ...state,
          searchRoomRecentlyList: action.data.data,
          roomChatListByUserId: [
            ...state.roomChatListByUserId,
            ...action.data.data,
          ],
          allRoomListInfo: action.data,
        };
      }
      if (action.search){
        console.log('action.data: 3', action.data);
        return {
          ...state,
          searchRoomRecentlyList:
            action.data.data.length &&
            action.data.data.filter((item) => {
              return !item.users.some(
                (user) => user.id === state.modalSelectUser?.data
              );
            }),
        };
      }
      console.log('action.data: 4', action.data);

      return {
        ...state,
        searchRoomRecentlyList: action.data.data,
        roomChatListByUserId: action.data.data,
        allRoomListInfo: action.data,
      };
    case c.SAVE_USER_ID_AD:
      return {
        ...state,
        userId: action.data,
      };
    case c.CREATE_ROOM_CHAT_AD:
      return {
        ...state,
        modalSelectUser: { ...state.modalSelectUser, isOpen: false },
        roomChatList: [action.data, ...state.roomChatList],
        // roomSelected: action.data,
      };
    // xóa phòng chat
    case c.DELETE_ROOM_CHAT_AD:
      return {
        ...state,
        roomChatList: state.roomChatList.filter(
          (item) => item.id !== action.data
        ),
        roomSelected: {},
      };
    case c.UPDATE_ROOM_CHAT_AD:
      return {
        ...state,
        roomChatList: state.roomChatList.map((item) => {
          return item.id === action.data.id ? action.data : item;
        }),
        roomSelected: action.data,
      };
    case c.UPDATE_MEMBER_ROOM_CHAT_AD:
      return {
        ...state,
        modalSelectUser: { ...state.modalSelectUser, isOpen: false },
      };
    case c.GET_ONE_ROOM_CHAT_AD:
      return state;
    case c.SELECTED_ROOM_CHAT_AD:
      if (
        state.roomChatList.length &&
        state.roomChatList.some((item) => item.id === action.data.id)
      ) {
        return {
          ...state,
          messageList: action.data.list_last_message,
          roomSelected: action.data,
          allMessageInfo: {},
          messageReplySelected: {},
          searchMessageList: [],
          isHideMainContent: false,
        };
      } else {
        return {
          ...state,
          messageList: action.data.list_last_message,
          roomSelected: action.data,
          allMessageInfo: {},
          messageReplySelected: {},
          searchMessageList: [],
          roomChatList: [action.data, ...state.roomChatList],
          isHideMainContent: false,
        };
      }
    case c.CHANGE_ROOM_CHAT_AD:
      if (state.roomChatList.length > 20) {
        let roomListClone = [...state.roomChatList];
        const roomUpdateIndex = state.roomChatList.findIndex(
          (item) => item.id === state.roomSelected?.id
        );
        const roomUpdate = action.data.list_room_chat.find(
          (item) => item.id === state.roomSelected?.id
        );
        if (roomUpdateIndex > 19) {
          roomListClone = roomListClone.filter(
            (item) => item.id !== roomUpdate.id
          );
          roomListClone.unshift(roomUpdate);
          return {
            ...state,
            roomChatList: roomListClone,
            roomSelected: roomUpdate,
          };
        } else {
          const restOldRoomList = state.roomChatList.slice(20);
          const newRoomList = [
            ...action.data.list_room_chat,
            ...restOldRoomList,
          ];
          return {
            ...state,
            roomChatList: newRoomList,
            roomSelected: roomUpdate,
          };
        }
      }

      return {
        ...state,
        roomChatList: action.data.list_room_chat,
        roomSelected:
          action.data.list_room_chat.find(
            (item) => item.id === state.roomSelected?.id
          ) || {},
      };
    case c.SEARCH_MEMBERS_AD:
      return {
        ...state,
        searchMemberList: action.data?.data,
      };

    // FUNCTION TIN NHẮN
    case c.CREATE_MESSAGE_AD:
      return {
        ...state,
        uniqueIdPresent: action.data.id,
        messageList: [action.data, ...state.messageList],
      };
    case c.UPDATE_MESSAGE_AD:
      const updatedMessageList = state.messageList.map((item, index) => {
        if (item.id === action.data.id) {
          return action.data;
        }
        return item;
      });
      return {
        ...state,
        uniqueIdPresent: action.data.id,
        messageList: updatedMessageList,
      };
    case c.RECEIVE_MESSAGE_AD:
      // xử lý việc nhận sự kiện đang nhập
      if (action.data?.data) {
        return {
          ...state,
          dataTyping: { ...action.data.data, roomId: action.data.room_id },
        };
      }
      // nhận tin nhắn update
      if (state.messageList.some((item) => item.id === action.data.id)) {
        const updatedMessageList = state.messageList.map((item, index) => {
          if (item.id === action.data.id) {
            return action.data;
          }
          return item;
        });
        return {
          ...state,
          messageList: updatedMessageList,
        };
      }
      // nhận tin nhắn từ người khác hoặc từ mình nhưng khác thiết bị
      if (action.data.id === state.uniqueIdPresent) return;
      return {
        ...state,
        messageList: [action.data, ...state.messageList],
      };
    case c.SELECTED_REPLY_MESSAGE_AD:
      return {
        ...state,
        messageReplySelected: action.data,
      };
    case c.GET_MESSAGE_LIST_AD:
      console.log("action.way: ", action.way);
      if (action.way === "search")
        return {
          ...state,
          searchMessageList: action.data.data,
        };
      const handleCurrentMessageList = () => {
        if (action.way === "first") return action.data.data;
        if (action.way === "newer")
          return [...action.data.data, ...state.messageList];
        if (action.way === "older")
          return [...state.messageList, ...action.data.data];
        return state.messageList;
      };

      return {
        ...state,
        allMessageInfo: action.data,
        messageList: handleCurrentMessageList(),
      };
    case c.REDIRECT_MESSAGE_AD:
      return {
        ...state,
        modalSelectUser: { ...state.modalSelectUser, isOpen: false },
      };
    case c.SHOW_LOADING_MODAL_AD:
      console.log("action.data: ", action.data);
      return {
        ...state,
        modalSelectUser: { ...state.modalSelectUser, loading: action.data },
      };
    case c.ADD_PIN_MESSAGE_AD: {
      // const updatedRoomChatList = state.roomChatList.map((item, index) => {
      //   if (item.id === action.data.id) {
      //     return action.data;
      //   }
      //   return item;
      // });
      // return {
      //   ...state,
      //   roomChatList: updatedRoomChatList,
      // };
    }

    case c.GET_FILE_LIST_AD:
      return {
        ...state,
        fileList: action.data.data,
      };

    // FUNCTION MODAL
    case c.TOGGLE_MODAL_SELECTS_USER_AD:
      return {
        ...state,
        modalSelectUser: {
          isOpen: action.data.isOpen,
          type: action.data.type,
          data: action.data.data,
          loading: false,
        },
        searchRoomRecentlyList: state.roomChatList,
        isHideMainContent: false,
      };
    case c.TOGGLE_MODAL_USER_INFO_AD:
      return {
        ...state,
        modalUserInfo: action.data,
      };
    case c.TOGGLE_FULLSCREEN_AD:
      return {
        ...state,
        fullscreen: action.data,
      };
    case c.TOGGLE_HIDE_MAIN_CONTENT_AD:
      return {
        ...state,
        isHideMainContent: action.data,
      };
    default:
      return state;
  }
}
