import { constants as c } from "../constants";
const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));

function getAllStaffs(queryString = "") {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(`${c.API_URL}/personnel/manager/all/staff${queryString}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function getAllDepartments(queryString = "") {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(`${c.API_URL}/departments${queryString}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}
function getAllManagers(queryString = "") {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(`${c.API_URL}/manager_departments${queryString}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function getStaffInfo(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(`${c.API_URL}/staffs/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function createStaff(info) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
    body: JSON.stringify(info),
  };
  return fetch(`${c.API_URL}/staffs`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function createMultiStaff(info) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
    body: JSON.stringify(info),
  };
  return fetch(`${c.API_URL}/staffs/create_multi`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function createMultiManagers(info) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
    body: JSON.stringify(info),
  };
  return fetch(`${c.API_URL}/manager_departments/create_multi`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function deleteStaff(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(`${c.API_URL}/staffs/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function deleteMultiStaff(info) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
    body: JSON.stringify(info),
  };
  return fetch(`${c.API_URL}/list_delete_staff`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function deleteManager(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(`${c.API_URL}/manager_departments/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}
function deleteMultiManagers(info) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
    body: JSON.stringify(info),
  };
  return fetch(`${c.API_URL}/list_delete_department_managers`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function updateStaff(id, info) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
    body: JSON.stringify(info),
  };
  return fetch(`${c.API_URL}/staffs/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function deleteDeparment(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(`${c.API_URL}/departments/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function createDeparment(info) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
    body: JSON.stringify(info),
  };
  return fetch(`${c.API_URL}/departments`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function updateDeparment(id, info) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
    body: JSON.stringify(info),
  };
  return fetch(`${c.API_URL}/departments/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}
function fetchRatingReviewStaff(areaId) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/review/staff/rating?area_id=${areaId}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}
function fetchHistoryReviewStaff(areaId, staffId) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/review/staff/default?area_id=${areaId}&staff_id=${staffId}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

const getHistoryStaff = async (staffId, page) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/personnel/1/staff/history_staff?staff_id=${staffId}&page=${page}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const getHistoryLogStaff = async (staffId, page, type) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/history-log/staff?staff_id=${staffId}&page=${page}&type=${type}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const getHistoryLogStaffForm = async (staffId, page, type) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/history-log/procedure-form?staff_id=${staffId}&page=${page}&type=${type}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const getHistoryContract = async (staffId, page) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/history-log/contract?staff_id=${staffId}&page=${page}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

export const staffsServices = {
  getAllStaffs,
  getStaffInfo,
  getAllDepartments,
  createStaff,
  updateStaff,
  deleteStaff,
  deleteDeparment,
  createDeparment,
  updateDeparment,
  createMultiStaff,
  getAllManagers,
  createMultiManagers,
  deleteManager,
  deleteMultiManagers,
  deleteMultiStaff,
  fetchRatingReviewStaff,
  fetchHistoryReviewStaff,
  getHistoryStaff,
  getHistoryContract,
  getHistoryLogStaff,
  getHistoryLogStaffForm,
};
