import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import * as XLSX from "xlsx";
import moment from "moment";
import { formatMoneyVND } from ".";

function getSheetData(data, header) {
  var fields = Object.keys(data[0]);
  var sheetData = data.map(function (row) {
    return fields.map(function (fieldName) {
      return row[fieldName] ? row[fieldName] : "";
    });
  });
  sheetData.unshift(header);
  return sheetData;
}

async function saveAsExcel(value, title) {
  var data = value.data;
  var data_header = value.header;
  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    const sheet1 = workbook.sheet(0);
    const sheetData = getSheetData(data, data_header);
    console.log(sheetData);
    const totalColumns = sheetData[0].length;
    sheet1.cell("A1").value(sheetData);
    const range = sheet1.usedRange();
    sheet1.row(1).style("bold", true);
    sheet1.range("A1:" + "AF" + "1").style("fill", "F4D03F");
    range.style("border", true);
    range.style("horizontalAlignment", "left");
    for (let i = 1; i <= totalColumns; i++) {
      const column = sheet1.column(i);
      column.width(25); // set width for each column
    }
    return workbook.outputAsync().then((res) => {
      saveAs(res, title);
    });
  });
}

function exportExcel(
  title,
  data,
  header,
  fillable = [],
  convertDateTime = [],
  convertBooleanToNumber = [],
  formatMoney = []
) {
  if (data.length > 0) {
    let newArray = [];
    for (const item of data) {
      var newItem = {};
      for (const fill of fillable) {
        let value = item;
        const keys = fill.key.split(".");
        if (keys.length > 1) {
          if (
            convertBooleanToNumber.length > 0 &&
            convertBooleanToNumber.includes(fill.key) &&
            value
          ) {
            value = value[keys[0]]?.[keys[1]] ? 1 : 0;
          } else if (formatMoney.length > 0 && formatMoney.includes(fill.key)) {
            value = value[keys[0]]?.[keys[1]]
              ? formatMoneyVND(value[keys[0]]?.[keys[1]])
              : "0";
          } else {
            if (typeof value[keys[0]]?.[keys[1]] === "undefined") {
              value = "";
            } else {
              value = `${value[keys[0]]?.[keys[1]]}`;
            }
          }
        } else {
          value = value[keys[0]];
        }
        if (
          convertDateTime?.length > 0 &&
          convertDateTime.includes(fill.key) &&
          value
        ) {
          value = moment(value, "YYYY-MM-DD", false).isValid()
            ? moment(value).format("DD/MM/YYYY")
            : "";
        }
        if (fill.key === "sex") {
          value = item[fill.key] === 1 ? "Nam" : value = item[fill.key] === 2 ? "Nữ" : 'Không xác định';
        }

        newItem[fill.display_name] = value;
      }
      newArray = [...newArray, newItem];
    }
    console.log(newArray);
    saveAsExcel({ data: newArray, header: header }, title);
  }
}

async function readFile(evt) {
  const reader = new FileReader();
  var f = evt.target.files[0];
  return new Promise((resolve, reject) => {
    reader.onload = async function (evt) {
      const bstr = evt.target.result;
      const workbook = XLSX.read(bstr, { type: "binary" });
      workbook.SheetNames.forEach((sheet) => {
        let rowObject = XLSX.utils.sheet_to_row_object_array(
          workbook.Sheets[sheet],
          {
            defval: "",
          }
        );
        resolve(rowObject);
      });
    };
    document.getElementById("file-excel-import").value = null;
    reader.readAsBinaryString(f);
  });
}

export { exportExcel, readFile };
