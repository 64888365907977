import { constants as c } from "../constants";

const areaIdLocalStorage = localStorage.getItem("areaId");
const initialState = {
  areas: {
    data: [],
    status: c.NONE,
  },
  areaId: areaIdLocalStorage ? parseInt(areaIdLocalStorage) : null,
};

export function area(state = initialState, action) {
  switch (action.type) {
    case c.GET_ALL_AREA_SUCCESS:
      let areaIdState = state.areaId;

      if (action.init === true) {
        console.log("========xxxxxxxxxx" + action.data);
        console.log("========xxxxxxxxxx" + action.reportNumber);
        console.log("========xxxxxxxxxx" + action.areaCurrentId);

        var areaSave = [];
        var listArea = [];

        (action.data ?? []).forEach((area) => {
          if ((action.reportNumber.area_ids ?? []).includes(area.id)) {
            areaSave.push(area);
          }
        });

        if (areaSave.length === 0) {
          const areas = action.data.filter(
            (a) => a.id === action.areaCurrentId
          );
          if (areas.length > 0) {
            listArea = [areas[0]];
          } else {
            listArea = [action.reportNumber.current_user.area];
          }
        } else {
          listArea = areaSave;
        }

        if (
          (action.reportNumber.area_ids ?? []).includes(areaIdState) === false
        ) {
          localStorage.setItem(
            "areaId",
            action.reportNumber.permissions["is_admin"]
              ? action.reportNumber.current_user.area_id
              : (action.reportNumber.area_ids ?? []).includes(
                  action.reportNumber.current_user.area_id
                )
              ? action.reportNumber.current_user.area_id
              : listArea[0].id
          );

          areaIdState = action.reportNumber.permissions["is_admin"]
            ? action.reportNumber.current_user.area_id
            : (action.reportNumber.area_ids ?? []).includes(
                action.reportNumber.current_user.area_id
              )
            ? action.reportNumber.current_user.area_id
            : listArea[0].id;
        }

        if ((action.data ?? []).length > 1) {
          listArea.unshift({ id: c.AREA_ALL_ID, name: "Tất cả" });
        }

        return {
          areaId: areaIdState,
          areas: {
            data: listArea,
            status: c.SUCCESS,
          },
        };
      } else {
        if (
          !areaIdLocalStorage &&
          Array.isArray(action.data) &&
          action.data.length > 0
        ) {
          if (areaIdLocalStorage != null) {
            localStorage.setItem("areaId", action.data[0]?.id);
            areaIdState = action.data[0]?.id;
          }
        }

        return {
          areaId: areaIdState,
          areas: {
            data: action.data,
            status: c.SUCCESS,
          },
        };
      }

    case c.CHANGE_AREA_ID:
      localStorage.setItem("areaId", action.data);
      return {
        ...state,
        areaId: action.data,
      };
    default:
      return state;
  }
}
