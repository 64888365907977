import { constants as c } from "../constants";
import { staffsServices as s } from "../services/staffsServices";
import history from "../history";
import { appServices as a } from "../services/appServices";

function messageInfo(res, showMessage = true) {
  //showMessage này chỉ dùng nếu check phân quyền, chuyển sang false để check
  if (showMessage !== true) {
    if (res.msg_code === "NOT_AUTHORIZATION") {
      window.location.href = `/${res.code}?code=${res.code}&msg=${res.msg}`;
      return;
    }
    return {
      type: c.UID_FAILURE,
      info: {
        status: res.success,
        msg: res.msg,
      },
    };
  } else {
    if (res.msg_code === "NOT_AUTHORIZATION") {
      window.location.href = `/${res.code}?code=${res.code}&msg=${res.msg}`;
      return;
    }
    return {
      type: c.UID_SUCCESS,
      info: {
        status: res.success,
        msg: res.msg,
      },
    };
  }
}

function getAllStaffs(queryString) {
  return (dispatch) => {
    dispatch({ type: c.CHANGE_LOADING, typeLoading: c.SHOW_LOADING });
    s.getAllStaffs(queryString).then((res) => {
      dispatch(messageInfo(res, false));
      if (res.code === 200) dispatch(success(res.data));
      else dispatch(failure(res.code, res.msg));
      dispatch({ type: c.CHANGE_LOADING, typeLoading: c.NONE_LOADING });
    });
  };
  function success(data) {
    return { type: c.GET_ALL_STAFFS_SUCCESS, data };
  }
  function failure(code, message) {
    return { type: c.GET_ALL_STAFFS_FAILURE, code, message };
  }
}

function getAllDepartments(queryString) {
  return (dispatch) => {
    s.getAllDepartments(queryString).then((res) => {
      dispatch(messageInfo(res, false));
      if (res.success === true) dispatch(success(res.data));
      else dispatch(failure(res.code, res.msg));
    });
  };
  function success(data) {
    return { type: c.GET_ALL_DEPARTMENT_SUCCESS, data };
  }
  function failure(code, message) {
    return { type: c.FAILURE, code, message };
  }
}

function getStaffInfo(id) {
  return (dispatch) => {
    s.getStaffInfo(id).then((res) => {
      dispatch(messageInfo(res, false));
      if (res.code === 200) dispatch(success(res.data));
      else dispatch(failure(res.code, res.msg));
    });
  };
  function success(data) {
    return { type: c.GET_STAFF_SUCCESS, data };
  }
  function failure(code, msg) {
    return { type: c.NOT_FOUND, code, msg };
  }
}

function createStaff(imgUpload, info) {
  return async (dispatch) => {
    dispatch({ type: c.CHANGE_LOADING, typeLoading: c.SHOW_LOADING });
    var avatar = info.avatar;
    if (imgUpload) {
      var resImage = await a.uploadImg(imgUpload);
      if (resImage.success === true) {
        avatar = resImage.data.image_url;
      } else {
        dispatch({ type: c.CHANGE_LOADING, typeLoading: c.NONE_LOADING });
        return;
      }
    }
    var res = await s.createStaff({ ...info, avatar });
    dispatch({ type: c.CHANGE_LOADING, typeLoading: c.NONE_LOADING });

    dispatch(messageInfo(res));
    if (res.code === 200 || res.code === 201) {
      dispatch({ type: c.RESET_STAFFS_LIST_STATUS });
      history.goBack();
    } else {
      dispatch(failure(res.code, res.msg));
    }
    function failure(code, msg) {
      return { type: c.FAILURE, code, msg };
    }
  };
}

function createMultiStaff(data) {
  return (dispatch) => {
    s.createMultiStaff(data).then((res) => {
      dispatch(messageInfo(res));
      if (res.success === true) {
        window.$(".modal").modal("hide");

        dispatch({ type: c.RESET_STAFFS_LIST_STATUS });

        // history.goBack()
      } else dispatch(failure(res.code, res.msg));
    });
  };
  function failure(code, msg) {
    return { type: c.FAILURE, code, msg };
  }
}
function createMultiManagers(data) {
  return (dispatch) => {
    s.createMultiManagers(data).then((res) => {
      dispatch(messageInfo(res));
      if (res.success === true) {
        window.$(".modal").modal("hide");

        dispatch({ type: c.RESET_ALL_MANAGERS_DEPARTMENT_STATUS });

        // history.goBack()
      } else dispatch(failure(res.code, res.msg));
    });
  };
  function failure(code, msg) {
    return { type: c.FAILURE, code, msg };
  }
}

function updateStaff(imgUpload, id, info) {
  return async (dispatch) => {
    dispatch({ type: c.CHANGE_LOADING, typeLoading: c.SHOW_LOADING });
    var avatar = info.avatar;
    if (imgUpload) {
      var resImage = await a.uploadImg(imgUpload);
      if (resImage.success === true) {
        avatar = resImage.data.image_url;
      } else {
        return;
      }
    }
    dispatch({ type: c.CHANGE_LOADING, typeLoading: c.NONE_LOADING });

    var res = await s.updateStaff(id, { ...info, avatar });
    dispatch(messageInfo(res));
    if (res.code === 200 || res.code === 201) {
      dispatch({ type: c.RESET_STAFFS_LIST_STATUS });
      history.goBack();
    } else {
      dispatch(failure(res.code, res.msg));
    }
    function failure(code, msg) {
      return { type: c.FAILURE, code, msg };
    }
  };
}

function deleteStaff(id) {
  return (dispatch) => {
    s.deleteStaff(id).then((res) => {
      dispatch(messageInfo(res));
      if (res.code === 201) {
        window.$(".modal").modal("hide");

        dispatch({ type: c.RESET_STAFFS_LIST_STATUS });
      } else dispatch(failure(res.code, res.msg));
    });
  };
  function failure(code, msg) {
    return { type: c.FAILURE, code, msg };
  }
}
function deleteMultiStaff(info) {
  return (dispatch) => {
    s.deleteMultiStaff(info).then((res) => {
      dispatch(messageInfo(res));
      if (res.code === 201) {
        window.$(".modal").modal("hide");

        dispatch({ type: c.RESET_STAFFS_LIST_STATUS });
      } else dispatch(failure(res.code, res.msg));
    });
  };
  function failure(code, msg) {
    return { type: c.FAILURE, code, msg };
  }
}

function deleteManager(id) {
  return (dispatch) => {
    s.deleteManager(id).then((res) => {
      dispatch(messageInfo(res));
      if (res.success === true) {
        window.$(".modal").modal("hide");

        dispatch({ type: c.RESET_ALL_MANAGERS_DEPARTMENT_STATUS });
      } else dispatch(failure(res.code, res.msg));
    });
  };
  function failure(code, msg) {
    return { type: c.FAILURE, code, msg };
  }
}
function deleteMultiManagers(info) {
  return (dispatch) => {
    s.deleteMultiManagers(info).then((res) => {
      dispatch(messageInfo(res));
      if (res.success === true) {
        window.$(".modal").modal("hide");

        dispatch({ type: c.RESET_ALL_MANAGERS_DEPARTMENT_STATUS });
      } else dispatch(failure(res.code, res.msg));
    });
  };
  function failure(code, msg) {
    return { type: c.FAILURE, code, msg };
  }
}

function createDeparment(info) {
  return (dispatch) => {
    s.createDeparment(info).then((res) => {
      dispatch(messageInfo(res));
      if (res.success === true) {
        window.$(".modal").modal("hide");
        dispatch({ type: c.RESET_DEPARTMENT_LIST_STATUS });
      } else dispatch(failure(res.code, res.msg));
    });
  };
  function failure(code, msg) {
    return { type: c.FAILURE, code, msg };
  }
}

function updateDeparment(id, info) {
  return (dispatch) => {
    s.updateDeparment(id, info).then((res) => {
      dispatch(messageInfo(res));
      if (res.success === true) {
        window.$(".modal").modal("hide");

        dispatch({ type: c.RESET_DEPARTMENT_LIST_STATUS });
      } else dispatch(failure(res.code, res.msg));
    });
  };
  function failure(code, msg) {
    return { type: c.FAILURE, code, msg };
  }
}

function deleteDeparment(id) {
  return (dispatch) => {
    s.deleteDeparment(id).then((res) => {
      dispatch(messageInfo(res));
      if (res.success === true) {
        window.$(".modal").modal("hide");

        dispatch({ type: c.RESET_DEPARTMENT_LIST_STATUS });
      } else dispatch(failure(res.code, res.msg));
    });
  };
  function failure(code, msg) {
    return { type: c.FAILURE, code, msg };
  }
}
function getAllManagers(queryString) {
  return (dispatch) => {
    dispatch({ type: c.CHANGE_LOADING, typeLoading: c.SHOW_LOADING });
    s.getAllManagers(queryString).then((res) => {
      dispatch(messageInfo(res, false));
      if (res.code === 200) dispatch(success(res.data));
      else dispatch(failure(res.code, res.msg));
      dispatch({ type: c.CHANGE_LOADING, typeLoading: c.NONE_LOADING });
    });
  };
  function success(data) {
    return { type: c.GET_ALL_MANAGERS_DEPARTMENT_SUCCESS, data };
  }
  function failure(code, message) {
    return { type: c.GET_ALL_MANAGERS_DEPARTMENT_FAILURE, code, message };
  }
}
export const staffAction = {
  getAllStaffs,
  getStaffInfo,
  getAllDepartments,
  createStaff,
  updateStaff,
  deleteStaff,
  deleteDeparment,
  createDeparment,
  updateDeparment,
  createMultiStaff,
  getAllManagers,
  createMultiManagers,
  deleteManager,
  deleteMultiManagers,
  deleteMultiStaff,
};
