import { constants as c } from "../constants";
import { studyServices as s } from "../services/studyServices";
import history from "../history";

function messageInfo(res) {
   return {
      type: c.UID_SUCCESS,
      info: {
         status: res.success,
         msg: res.msg,
      },
   };
}

function getAllStudies(queryString) {
   return (dispatch) => {
      dispatch({ type: c.CHANGE_LOADING, typeLoading: c.SHOW_LOADING });
      s.getAllStudies(queryString).then((res) => {
         if (res.code === 200) dispatch(success(res.data));
         else dispatch(failure(res.code, res.msg));
         dispatch({ type: c.CHANGE_LOADING, typeLoading: c.NONE_LOADING });
      });
   };
   function success(data) {
      return { type: c.GET_ALL_STUDIES_SUCCESS, data };
   }
   function failure(code, message) {
      return { type: c.GET_ALL_STUDIES_FAILURE, code, message };
   }
}

function getAllDepartments(queryString) {
   return (dispatch) => {
      s.getAllDepartments(queryString).then((res) => {
         if (res.success === true) dispatch(success(res.data));
         else dispatch(failure(res.code, res.msg));
      });
   };
   function success(data) {
      return { type: c.GET_ALL_DEPARTMENT_SUCCESS, data };
   }
   function failure(code, message) {
      return { type: c.FAILURE, code, message };
   }
}

function getStudyInfo(id) {
   return (dispatch) => {
      s.getStudyInfo(id).then((res) => {
         if (res.code === 200) dispatch(success(res.data));
         else dispatch(failure(res.code, res.msg));
      });
   };
   function success(data) {
      return { type: c.GET_Study_SUCCESS, data };
   }
   function failure(code, msg) {
      return { type: c.NOT_FOUND, code, msg };
   }
}

function createMulti(info) {
   return (dispatch) => {
      s.createMulti(info).then((res) => {
         dispatch(messageInfo(res));
         if (res.success === true) {
            window.$(".modal").modal("hide");
            dispatch({ type: c.RESET_STUDIES_LIST_STATUS });
         } else dispatch(failure(res.code, res.msg));
      });
   };
   function failure(code, msg) {
      return { type: c.FAILURE, code, msg };
   }
}

function updateStudy(id, info) {
   return (dispatch) => {
      s.updateStudy(id, info).then((res) => {
         dispatch(messageInfo(res));
         if (res.code === 201) {
            dispatch({ type: c.RESET_STUDIES_LIST_STATUS });
            history.goBack();
         } else dispatch(failure(res.code, res.msg));
      });
   };
   function failure(code, msg) {
      return { type: c.FAILURE, code, msg };
   }
}

function deleteStudy(id) {
   return (dispatch) => {
      s.deleteStudy(id).then((res) => {
         dispatch(messageInfo(res));
         if (res.success === true) {
            window.$(".modal").modal("hide");

            dispatch({ type: c.RESET_STUDIES_LIST_STATUS });
         } else dispatch(failure(res.code, res.msg));
      });
   };
   function failure(code, msg) {
      return { type: c.FAILURE, code, msg };
   }
}

function deleteMultiStudy(info) {
   return (dispatch) => {
      s.deleteMultiStudy(info).then((res) => {
         dispatch(messageInfo(res));
         if (res.success === true) {
            window.$(".modal").modal("hide");

            dispatch({ type: c.RESET_STUDIES_LIST_STATUS });
         } else dispatch(failure(res.code, res.msg));
      });
   };
   function failure(code, msg) {
      return { type: c.FAILURE, code, msg };
   }
}



export const studyActions = {
   getAllStudies,
   getStudyInfo,
   getAllDepartments,
   createMulti,
   updateStudy,
   deleteStudy,
   createMulti,
   deleteMultiStudy
};
