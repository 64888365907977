import React, { Component } from "react";

export default function  Loading() {
  
    return (
      <div id="loader-wrapper">
        <div id="loader"></div>
      </div>
    );
  }


