export default function ZoomInIcon({ className, ...rest }) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...rest}
    >
      <circle cx="6" cy="6" r="6" fill="#F1B930" />
      <path
        d="M4 6H8"
        stroke="black"
        stroke-width="0.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
