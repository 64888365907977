import { constants as c } from "../constants";
const initialState = {
  show: false,
  type_noti: null,
  reference_value: null,
};
export function notiNavi(state = initialState, action) {
  switch (action.type) {
    case c.MODAL_NOTI_HIDE:
      return {
        ...state,
        show: false,
        type_noti: null,
        reference_value: null,
      };
    case c.MODAL_NOTI_SHOW:
      return {
        ...state,
        show: true,
        type_noti: action.type_noti,
        reference_value: action.reference_value,
      };
    default:
      return state;
  }
}
