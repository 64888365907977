export default function ZoomOutIcon({ className, ...rest }) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...rest}
    >
      <circle cx="6" cy="6" r="6" fill="#F1B930" />
      <g clip-path="url(#clip0_522_29)">
        <path
          d="M4.35291 6.35526L6.36691 4.44435C6.38724 4.42493 6.40189 4.40035 6.40929 4.37323C6.41668 4.34611 6.41654 4.31749 6.40888 4.29044C6.40122 4.2634 6.38633 4.23896 6.36581 4.21975C6.34529 4.20053 6.31992 4.18728 6.29243 4.18142L3.90532 3.68232C3.80637 3.66161 3.70589 3.75694 3.72127 3.85695L4.09437 6.26697C4.09863 6.2948 4.11044 6.32094 4.12851 6.34254C4.14658 6.36413 4.17023 6.38036 4.19688 6.38946C4.22353 6.39856 4.25216 6.40019 4.27967 6.39416C4.30718 6.38812 4.33251 6.37467 4.35291 6.35526Z"
          fill="black"
        />
      </g>
      <g clip-path="url(#clip1_522_29)">
        <path
          d="M7.66762 5.64858L5.63244 7.53691C5.61189 7.5561 5.59697 7.58052 5.58927 7.60755C5.58157 7.63459 5.58139 7.66321 5.58875 7.69034C5.59611 7.71747 5.61073 7.74207 5.63104 7.76151C5.65134 7.78095 5.67656 7.79448 5.70399 7.80065L8.08538 8.32634C8.1841 8.34815 8.28563 8.25395 8.27137 8.15377L7.92516 5.73974C7.92121 5.71186 7.9097 5.68559 7.89186 5.6638C7.87403 5.642 7.85057 5.62551 7.82402 5.61611C7.79748 5.60671 7.76886 5.60477 7.74129 5.6105C7.71371 5.61622 7.68824 5.62939 7.66762 5.64858Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_522_29">
          <rect
            width="3.70172"
            height="3.70172"
            fill="white"
            transform="translate(2 4.54785) rotate(-43.4954)"
          />
        </clipPath>
        <clipPath id="clip1_522_29">
          <rect
            width="3.70172"
            height="3.70172"
            fill="white"
            transform="translate(10 7.48218) rotate(137.143)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
