import { constants as c } from "../constants";
const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));

function getAllStudies(queryString="") {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "token": tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/studies${queryString}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function getAllDepartments(queryString) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "token": tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/departments`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}



function getStudyInfo(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "token": tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/studies/${id}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function createMulti(info) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "token": tokenInfo ? tokenInfo : "",
    },
    body: JSON.stringify(info),

  };
  return fetch(
    `${c.API_URL}/studies/create_multi`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}


function deleteStudy(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "token": tokenInfo ? tokenInfo : "",
    },

  };
  return fetch(
    `${c.API_URL}/studies/${id}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function deleteMultiStudy(info) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "token": tokenInfo ? tokenInfo : "",
    },
    body: JSON.stringify(info),

  };
  return fetch(
    `${c.API_URL}/list_delete_study`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}



function updateStudy(id,info) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "token": tokenInfo ? tokenInfo : "",
    },
    body: JSON.stringify(info),

  };
  return fetch(
    `${c.API_URL}/studies/${id}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}




function deleteDeparment(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "token": tokenInfo ? tokenInfo : "",
    },

  };
  return fetch(
    `${c.API_URL}/departments/${id}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function createDeparment(info) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "token": tokenInfo ? tokenInfo : "",
    },
    body: JSON.stringify(info),

  };
  return fetch(
    `${c.API_URL}/departments`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}




function updateDeparment(id, info) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "token": tokenInfo ? tokenInfo : "",
    },
    body: JSON.stringify(info),

  };
  return fetch(
    `${c.API_URL}/departments/${id}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}
export const studyServices = {
  getAllStudies,
  getStudyInfo,
  getAllDepartments,
  createMulti,
  updateStudy,
  deleteStudy,
  deleteDeparment,
  createDeparment,
  updateDeparment,
  deleteMultiStudy
};
