export default function CloseIcon({ className, ...rest }) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...rest}
    >
      <circle cx="6" cy="6" r="6" fill="#F55D58" />
      <path
        d="M8.42701 8.07302C8.45024 8.09625 8.46867 8.12382 8.48124 8.15417C8.49381 8.18452 8.50028 8.21705 8.50028 8.2499C8.50028 8.28274 8.49381 8.31527 8.48124 8.34562C8.46867 8.37597 8.45024 8.40354 8.42701 8.42677C8.40379 8.45 8.37621 8.46842 8.34586 8.48099C8.31552 8.49356 8.28299 8.50003 8.25014 8.50003C8.21729 8.50003 8.18476 8.49356 8.15442 8.48099C8.12407 8.46842 8.09649 8.45 8.07326 8.42677L6.00014 6.35333L3.92701 8.42677C3.8801 8.47368 3.81648 8.50003 3.75014 8.50003C3.6838 8.50003 3.62017 8.47368 3.57326 8.42677C3.52635 8.37986 3.5 8.31624 3.5 8.2499C3.5 8.18355 3.52635 8.11993 3.57326 8.07302L5.6467 5.99989L3.57326 3.92677C3.52635 3.87986 3.5 3.81624 3.5 3.74989C3.5 3.68355 3.52635 3.61993 3.57326 3.57302C3.62017 3.52611 3.6838 3.49976 3.75014 3.49976C3.81648 3.49976 3.8801 3.52611 3.92701 3.57302L6.00014 5.64646L8.07326 3.57302C8.12017 3.52611 8.1838 3.49976 8.25014 3.49976C8.31648 3.49976 8.3801 3.52611 8.42701 3.57302C8.47392 3.61993 8.50028 3.68355 8.50028 3.74989C8.50028 3.81624 8.47392 3.87986 8.42701 3.92677L6.35358 5.99989L8.42701 8.07302Z"
        fill="black"
      />
    </svg>
  );
}
