import { constants as c } from "../constants";
const initialState = {
  messageList: [],
  roomSelected: {},
  roomSelectedBefore: {},
  roomChatList: [],
  roomChatListByUserId: [],
  allRoomListInfo: {},
  userId: "",
  messageReplySelected: {},
  uniqueIdPresent: "",
  status: c.NONE,
  allMessageInfo: {},
  searchRoomRecentlyList: [],
  searchMemberList: [],
  searchMessageList: [],
  dataTyping: {},
  fileList: [],
  isShowBox: false,
  modalSelectUser: {
    isOpen: false,
    type: "",
    data: {},
    loading: false,
  },
  fullscreen: false,
  isHideMainContent: true,
  modalUserInfo: {
    isOpen: false,
    data: {},
  },
  loadingDrawer: false,
  loadingFile: false,
};
export function chat(state = initialState, action) {
  switch (action.type) {
    // FUNCTION BOX CHAT

    case c.TOGGLE_BOX_CHAT:
      return {
        ...state,
        isShowBox: action.data,
      };

    // FUNCTION PHÒNG CHAT
    case c.GET_ROOM_CHAT_SUCCESS:
      // loại bỏ những phòng có id trùng nhau
      // const roomChatList = action.data.data.filter(
      //   (item, index, self) => index === self.findIndex((t) => t.id === item.id)
      // );
      if (action.data.current_page > 1) {
        let filter = {};

        let merged = [
          ...state.roomChatList.filter((item) => {
            if (!filter[item.id]) {
              filter[item.id] = true;
              return true;
            }
            return false;
          }),

          ...action.data.data.filter((item) => {
            if (!filter[item.id]) {
              filter[item.id] = true;
              return true;
            }
            return false;
          }),
        ];
        return {
          ...state,
          searchRoomRecentlyList: action.data.data,
          // roomChatList: [...state.roomChatList, ...roomChatList],
          roomChatList: merged,
          allRoomListInfo: action.data,
        };
      }
      if (action.search)
        return {
          ...state,
          searchRoomRecentlyList:
            action.data.data.length &&
            action.data.data.filter((item) => {
              return !item.users.some(
                (user) => user.id === state.modalSelectUser?.data
              );
            }),
        };
      return {
        ...state,
        searchRoomRecentlyList: action.data.data,
        roomChatList: action.data.data,
        allRoomListInfo: action.data,
        messageList:
          state?.roomSelected?.id != null
            ? action.data.data.find((item) => item.id == state.roomSelected.id)
                .list_last_message
            : [],
      };

    case c.GET_ROOM_CHAT_BY_USER_ID:
      if (action.data.current_page > 1) {
        return {
          ...state,
          searchRoomRecentlyList: action.data.data,
          roomChatListByUserId: [
            ...state.roomChatListByUserId,
            ...action.data.data,
          ],
          allRoomListInfo: action.data,
        };
      }
      if (action.search)
        return {
          ...state,
          searchRoomRecentlyList:
            action.data.data.length &&
            action.data.data.filter((item) => {
              return !item.users.some(
                (user) => user.id === state.modalSelectUser?.data
              );
            }),
        };
      return {
        ...state,
        searchRoomRecentlyList: action.data.data,
        roomChatListByUserId: action.data.data,
        allRoomListInfo: action.data,
      };
    case c.SAVE_USER_ID:
      return {
        ...state,
        userId: action.data,
      };
    case c.CREATE_ROOM_CHAT:
      return {
        ...state,
        modalSelectUser: { ...state.modalSelectUser, isOpen: false },
        roomChatList: [action.data, ...state.roomChatList],
        roomSelected: action.data,
        messageList: [],
      };
    // xóa phòng chat
    case c.DELETE_ROOM_CHAT:
      return {
        ...state,
        roomChatList: state.roomChatList.filter(
          (item) => item.id !== action.data
        ),
        roomSelected: {},
      };
    case c.UPDATE_ROOM_CHAT:
      return {
        ...state,
        roomChatList: state.roomChatList.map((item) => {
          return item.id === action.data.id ? action.data : item;
        }),
        roomSelected: action.data,
      };
    case c.UPDATE_MEMBER_ROOM_CHAT:
      return {
        ...state,
        modalSelectUser: { ...state.modalSelectUser, isOpen: false },
      };
    case c.UPDATE_MESS_ROOM_CHAT:
      return {
        ...state,
        messageList: action.data,
      };
    case c.GET_ONE_ROOM_CHAT:
      return state;
    case c.SELECTED_ROOM_CHAT:
      if (
        state.roomChatList.length &&
        state.roomChatList.some((item) => item.id === action.data.id)
      ) {
        console.log("action.data: 1", action.data);
        console.log("list_last_message", action.data.list_last_message);

        return {
          ...state,
          messageList: action.data.list_last_message,
          roomSelected: action.data,
          allMessageInfo: {},
          messageReplySelected: {},
          searchMessageList: [],
          isHideMainContent: false,
        };
      } else {
        return {
          ...state,
          messageList: action.data.list_last_message,
          roomSelected: action.data,
          allMessageInfo: {},
          messageReplySelected: {},
          searchMessageList: [],
          roomChatList: [action.data, ...state.roomChatList],
          isHideMainContent: false,
        };
      }
    case c.CHANGE_ROOM_CHAT:
      let roomListClone = [...state.roomChatList];

      var index = roomListClone.findIndex(
        (e) => e.id === action.data.list_room_chat[0].id
      );

      var roomChatUpdate = action.data.list_room_chat[0];

      if (index !== -1) {
        if (roomChatUpdate.is_update_message != true) {
          // nếu là tin nhắn mới thì đẩy lên đầu
          roomListClone.splice(index, 1);
          roomListClone.unshift(roomChatUpdate);
        } else {
          // nếu là tin nhắn cũ thì cập nhật tại vị trí đó
          roomListClone[index] = roomChatUpdate;
        }
      } else {
        if (roomChatUpdate.is_update_message != true) {
          // nếu là tin nhắn mới thì đẩy lên đầu
          roomListClone.unshift(roomChatUpdate);
        }
      }

      return {
        ...state,
        roomChatList: roomListClone,
        roomSelectedBefore: state.roomSelected,
        roomSelected:
          roomListClone.find((item) => item.id === state.roomSelected?.id) ||
          {},
      };
    case c.SEARCH_MEMBERS:
      return {
        ...state,
        searchMemberList: action.data?.data,
      };

    // FUNCTION TIN NHẮN
    case c.LOADING_FILE:
      return {
        ...state,
        loadingFile: action.data,
      };
    case c.CREATE_MESSAGE:
      return {
        ...state,
        uniqueIdPresent: action.data.id,
        messageList: [action.data, ...state.messageList],
      };
    case c.UPDATE_MESSAGE:
      const updatedMessageList = state.messageList.map((item, index) => {
        if (item.id === action.data.id) {
          return action.data;
        }
        return item;
      });
      return {
        ...state,
        uniqueIdPresent: action.data.id,
        messageList: updatedMessageList,
      };
    case c.RECEIVE_MESSAGE:
      // xử lý việc nhận sự kiện đang nhập
      if (action.data?.data) {
        return {
          ...state,
          dataTyping: { ...action.data.data, roomId: action.data.room_id },
        };
      }
      // nhận tin nhắn update
      if (state.messageList.some((item) => item.id === action.data.id)) {
        const updatedMessageList = state.messageList.map((item, index) => {
          if (item.id === action.data.id) {
            return action.data;
          }
          return item;
        });
        return {
          ...state,
          messageList: updatedMessageList,
        };
      }
      // nhận tin nhắn từ người khác hoặc từ mình nhưng khác thiết bị
      if (action.data.id === state.uniqueIdPresent) return;
      return {
        ...state,
        messageList: [action.data, ...state.messageList],
      };
    case c.SELECTED_REPLY_MESSAGE:
      return {
        ...state,
        messageReplySelected: action.data,
      };
    case c.GET_MESSAGE_LIST:
      console.log("action.way: ", action.way);
      if (action.way === "search")
        return {
          ...state,
          searchMessageList: action.data.data,
        };
      const handleCurrentMessageList = () => {
        if (action.way === "first") return action.data.data;
        if (action.way === "newer")
          return [...action.data.data, ...state.messageList];
        if (action.way === "older")
          return [...state.messageList, ...action.data.data];
        return state.messageList;
      };

      return {
        ...state,
        allMessageInfo: action.data,
        messageList: handleCurrentMessageList(),
      };
    case c.REDIRECT_MESSAGE:
      return {
        ...state,
        modalSelectUser: { ...state.modalSelectUser, isOpen: false },
      };
    case c.SHOW_LOADING_MODAL:
      console.log("action.data: ", action.data);
      return {
        ...state,
        modalSelectUser: { ...state.modalSelectUser, loading: action.data },
      };
    case c.SHOW_LOADING_DRAWER:
      return {
        ...state,
        loadingDrawer: action.data,
      };
    case c.ADD_PIN_MESSAGE: {
      // const updatedRoomChatList = state.roomChatList.map((item, index) => {
      //   if (item.id === action.data.id) {
      //     return action.data;
      //   }
      //   return item;
      // });
      // return {
      //   ...state,
      //   roomChatList: updatedRoomChatList,
      // };
    }

    case c.GET_FILE_LIST:
      return {
        ...state,
        fileList: action.data.data,
      };

    // FUNCTION MODAL
    case c.TOGGLE_MODAL_SELECTS_USER:
      return {
        ...state,
        modalSelectUser: {
          isOpen: action.data.isOpen,
          type: action.data.type,
          data: action.data.data,
          loading: false,
        },
        searchRoomRecentlyList: state.roomChatList,
        isHideMainContent: false,
      };
    case c.TOGGLE_MODAL_USER_INFO:
      return {
        ...state,
        modalUserInfo: action.data,
      };
    case c.TOGGLE_FULLSCREEN:
      return {
        ...state,
        fullscreen: action.data,
      };
    case c.TOGGLE_HIDE_MAIN_CONTENT:
      return {
        ...state,
        isHideMainContent: action.data,
      };
    default:
      return state;
  }
}
