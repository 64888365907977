import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import * as XLSX from "xlsx";
import moment from "moment";
import { formatMoneyVND } from ".";
import { ar } from "date-fns/locale";
import { toast } from "react-toastify";

function getSheetData(data, header) {
  var fields = Object.keys(data[0]);
  var sheetData = data.map(function (row) {
    return fields.map(function (fieldName) {
      return row[fieldName] ? row[fieldName] : "";
    });
  });
  sheetData.unshift(header);
  return sheetData;
}

function convertNumberToColumnName(number) {
  const base = "A".charCodeAt(0); // Chữ cái 'A' tương ứng với mã ASCII 65
  let columnName = "";

  while (number > 0) {
    const modulo = (number - 1) % 26;
    columnName = String.fromCharCode(base + modulo) + columnName;
    number = parseInt((number - modulo) / 26, 10);
  }

  return columnName;
}

function exportStaffExcel(
  staffs,
  keyShow = [],
  areas,
  departements,
  roles,
  onDone
) {
  if (staffs.length > 0) {
    var row = [
      [
        ...keyShow
          .filter((e) => {
            return listKeyInfo.includes(e);
          })

          .map((e) => {
            return "Thông tin";
          }),
        ...keyShow
          .filter((e) => {
            return listKeyContract.includes(e);
          })
          .map((e) => {
            return "Hợp đồng";
          }),
      ],
    ]; // là 1 hàng trên excel
    var header = []; // hàng đầu tiên của file excel
    keyShow.forEach((key) => {
      header.push(mapData[key]);
    });

    row.push(header);
    staffs.forEach((s) => {
      var staff = {
        ...s.contract,
        ...s,
      };

      var item = [];

      keyShow.forEach((key) => {
        item = [
          ...item,
          handleData(staff[key], key, areas, departements, roles),
        ];
      });
      row.push(item);
    });

    console.log(row);

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      sheet1.cell("A1").value(row);
      sheet1.row(2).style({ bold: true, fill: "F4D03F" });
      const range = sheet1.usedRange();
      range.style("border", true);
      range.style("horizontalAlignment", "left");

      sheet1
        .range(
          `A1:${convertNumberToColumnName(
            keyShow.filter((e) => {
              return listKeyInfo.includes(e);
            }).length
          )}1`
        )
        .merged(true)
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontSize: 20,
          fill: "F6B40A",
          bold: true,
        });
      if (
        keyShow.filter((e) => {
          return listKeyContract.includes(e);
        }).length > 0 /// check có export hợp đồng không
      ) {
        sheet1
          .range(
            `${convertNumberToColumnName(
              keyShow.filter((e) => {
                return listKeyInfo.includes(e);
              }).length + 1
            )}1:${convertNumberToColumnName(keyShow.length)}1`
          )
          .merged(true)
          .style({
            horizontalAlignment: "center",
            verticalAlignment: "center",
            fontSize: 20,
            fill: "FEF3ED",
            bold: true,
          });
      }

      sheet1.row(1).height(30);
      sheet1.row(2).height(20);
      for (let i = 1; i <= keyShow.length; i++) {
        const column = sheet1.column(i);
        column.style({ wrapText: true });
        column.width(25); // set width for each column
      }
      return workbook.outputAsync().then((res) => {
        saveAs(res, "Danhsachnhanvien.xlsx");
        onDone();
      });
    });
  }
}

const handleData = (data, key, areas, departments, roles) => {
  if (
    key === "start_join_time" ||
    key === "birthday" ||
    key === "date_range" ||
    key === "start" ||
    key === "end"
  ) {
    return data == null ? "" : moment(data).format("DD-MM-YYYY");
  }

  if (key === "area_id") {
    return areas.find((e) => e.id == data)?.name;
  }

  if (key === "department_id") {
    return departments.find((e) => e.id == data)?.name;
  }

  if (key === "role_id") {
    return roles.find((e) => e.id == data)?.title;
  }

  if (
    key === "total_salary" ||
    key === "salary" ||
    key === "position_salary" ||
    key === "dependent_another" ||
    key === "seniority_limit" ||
    key === "support_productive" ||
    key === "support_result" ||
    key === "pc_food" ||
    key === "pc_phone" ||
    key === "pc_move"
  ) {
    return formatMoneyVND(data, true);
  }

  if (key === "supports" && data) {
    console.log(data);
    return `${data
      .map((e) => {
        return `${e.id}`;
      })
      .join(",")}`;
  }

  if (key === "BHXH") {
    return data == true ? "Có" : "Không";
  }

  if (key === "married") {
    return data == true ? "Có" : "Không";
  }

  if (key === "sex") {
    return data == 1 ? "Nam" : data == 2 ? "Nữ" : "Chưa xác định";
  }

  if (key === "cong_doan") {
    return data == true ? "Có" : "Không";
  }

  return data ?? "";
};

const mapData = {
  id: "ID",
  name: "Họ và tên",
  email: "Email",
  phone_number: "Số điện thoại",
  sex: "Giới tính",
  position: "Vị trí",
  department_id: "Phòng ban",
  start_join_time: "Ngày vào làm",
  staff_code: "Mã nhân viên",
  work_place: "Nơi làm việc",
  area_id: "Chi nhánh",
  birthday: "Ngày sinh",
  cmnd: "Số CMND/CCCD",
  date_range: "Ngày cấp",
  issued_by: "Nơi cấp",
  permanent_address: "Địa chỉ thường trú",
  temporary_address: "Địa chỉ tạm trú",
  bank_number: "Số tài khoản",
  bank_name: "Tên ngân hàng",
  bank_account_name: "Tên chủ tài khoản",
  bank_branch: "Chi nhánh ngân hàng",
  school: "Trường học",
  level: "Trình độ",
  specialized: "Chuyên ngành",
  course: "Khóa học",
  height: "Chiều cao",
  weight: "Cân nặng",
  congenital: "Bệnh di truyền",
  married: "Tình trạng hôn nhân",
  nation: "Dân tộc",
  religion: "Tôn giáo",
  cmnd_front: "Ảnh mặt trước CMND",
  cmnd_back_side: "Ảnh mặt sau CMND",
  role_id: "Phân quyền",
  date_leave: "Ngày nghỉ việc",
  leave_reason: "Lý do nghỉ việc",
  healthcare_first_place: "Nơi khám sức khỏe ban đầu",
  tax_code: "Mã số thuế",
  bhyt_code: "Mã số BHYT",
  /// contract
  type_key: "Loại hợp đồng",
  start: "Ngày bắt đầu",
  end: "Ngày kết thúc",
  salary: "Lương theo giờ",
  BHXH: "BHXH",
  BHYT: "BHYT",
  BHTN: "BHTN",
  tax: "Thuế TNCN",
  dependent_count: "Số người phụ thuộc",
  dependent_another: "Khoản miễn thuế khác",
  position_salary: "Lương vị trí (P1)",
  count_day_work: "Số ngày công",
  kpi_percent: "% KPI",
  annual_leave: "Ngày phép năm",
  unpaid_leave: "Ngày nghỉ không lương",
  compassionate_leave: "Ngày nghỉ tang",
  marriage_leave: "Ngày nghỉ kết hôn",
  leave: "Tình trạng hợp đồng",
  probation: "Đang thử việc",
  seniority_percent: "Phụ cấp thâm niên",
  seniority_limit: "Hạn mức phụ cấp thâm niên",
  image_url: "Hình ảnh hợp đồng",
  supports: "Phụ cấp (ID)",
  cong_doan: "Công đoàn",
  pc_food: "PC ăn trưa",
  pc_phone: "PC điện thoại",
  pc_move: "PC di chuyển",
  support_productive: "Lương P2",
  support_result: "Lương P3",
};

const listKeyInfo = [
  "id",
  "name",
  "email",
  "phone_number",
  "sex",
  "position",
  "department_id",
  "start_join_time",
  "staff_code",
  "work_place",
  "area_id",
  "birthday",
  "cmnd",
  "date_range",
  "issued_by",
  "permanent_address",
  "temporary_address",
  "bank_number",
  "bank_name",
  "bank_account_name",
  "bank_branch",
  "school",
  "level",
  "specialized",
  "course",
  "height",
  "weight",
  "congenital",
  "married",
  "nation",
  "religion",
  "cmnd_front",
  "cmnd_back_side",
  "role_id",
  "date_leave",
  "leave_reason",
  "healthcare_first_place",
  "tax_code",
  "bhyt_code",
];

const listKeyContract = [
  "type_key",
  "start",
  "end",
  "salary",
  "BHXH",
  "BHYT",
  "BHTN",
  "tax",
  "dependent_count",
  "dependent_another",
  "total_salary",
  "position_salary",
  "count_day_work",
  "kpi_percent",
  "annual_leave",
  "unpaid_leave",
  "compassionate_leave",
  "marriage_leave",
  "leave",
  "probation",
  "seniority_percent",
  "seniority_limit",
  "image_url",
  "supports",
  "cong_doan",
  "pc_food",
  "pc_phone",
  "pc_move",
  "support_productive", // lương p2
  "support_result", // lương p3
];

async function readFileStaff(evt) {
  const reader = new FileReader();
  var f = evt.target.files[0];
  return new Promise((resolve, reject) => {
    reader.onload = async function (evt) {
      const bstr = evt.target.result;
      const workbook = XLSX.read(bstr, { type: "binary" });

      // Assuming the first sheet is the one you want to modify
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Decode the range to manipulate it
      const decodedRange = XLSX.utils.decode_range(worksheet["!ref"]);

      // Remove the first row by adjusting the start and end row values
      for (let R = decodedRange.s.r; R <= decodedRange.e.r; ++R) {
        for (let C = decodedRange.s.c; C <= decodedRange.e.c; ++C) {
          const cellAddress = { c: C, r: R };
          const cellRef = XLSX.utils.encode_cell(cellAddress);
          const nextCellRef = XLSX.utils.encode_cell({ c: C, r: R + 1 });

          // Move the value from the next row to the current row
          worksheet[cellRef] = worksheet[nextCellRef];
        }
      }

      // Adjust the range to exclude the first row
      decodedRange.e.r--;

      // Update the worksheet's range
      worksheet["!ref"] = XLSX.utils.encode_range(decodedRange);

      workbook.SheetNames.forEach((sheet) => {
        let rowObject = XLSX.utils.sheet_to_row_object_array(worksheet, {
          defval: "",
        });
        resolve(rowObject);
      });
    };
    document.getElementById("file-excel-import").value = null;
    reader.readAsBinaryString(f);
  });
}

function handleDataImport(data, index, areas, departments, roles) {
  var v = data;
  try {
    if ((v.id || "") != "") {
      // data này là data cập nhật
      if ((v.department_id ?? "") != "") {
        v.department_id = departments.find(
          (e) => e.name == v.department_id
        )?.id;
      }
      if ((v.area_id ?? "") != "") {
        v.area_id = areas.find((e) => e.name == v.area_id)?.id;
      }
      if ((v.role_id ?? "") != "") {
        v.role_id = roles.find((e) => e.title == v.role_id)?.id;
      }
      if ((v.start_join_time ?? "") != "") {
        v.start_join_time = moment(v.start_join_time, "DD-MM-YYYY").format(
          "YYYY-MM-DD HH:mm:ss"
        );
      }
      if ((v.birthday ?? "") != "") {
        v.birthday = moment(v.birthday, "DD-MM-YYYY").format("YYYY-MM-DD");
      }
      if ((v.date_range ?? "") != "") {
        v.date_range = moment(v.date_range, "DD-MM-YYYY").format("YYYY-MM-DD");
      }

      v.phone_number = null;

      /// check hợp đồng

      if ((v.start ?? "") != "") {
        v.start = moment(v.start, "DD-MM-YYYY").format("YYYY-MM-DD");
      }
      if ((v.end ?? "") != "") {
        v.end = moment(v.end, "DD-MM-YYYY").format("YYYY-MM-DD");
      }

      if ((v.salary ?? "") != "") {
        v.salary = v.salary.toString().replaceAll(".", "").replaceAll(",", ".");
      }

      if ((v.total_salary ?? "") != "") {
        v.total_salary = v.total_salary
          .toString()
          .replaceAll(".", "")
          .replaceAll(",", ".");
      }

      if ((v.support_productive ?? "") != "") {
        v.support_productive = v.support_productive
          .toString()
          .replaceAll(".", "")
          .replaceAll(",", ".");
      }

      if ((v.support_result ?? "") != "") {
        v.support_result = v.support_result
          .toString()
          .replaceAll(".", "")
          .replaceAll(",", ".");
      }

      if ((v.pc_food ?? "") != "") {
        v.pc_food = v.pc_food
          .toString()
          .replaceAll(".", "")
          .replaceAll(",", ".");
      }

      if ((v.pc_phone ?? "") != "") {
        v.pc_phone = v.pc_phone
          .toString()
          .replaceAll(".", "")
          .replaceAll(",", ".");
      }

      if ((v.pc_move ?? "") != "") {
        v.pc_move = v.pc_move
          .toString()
          .replaceAll(".", "")
          .replaceAll(",", ".");
      }

      if ((v.seniority_limit ?? "") != "") {
        v.seniority_limit = v.seniority_limit
          .toString()
          .replaceAll(".", "")
          .replaceAll(",", ".");
      }

      if ((v.supports ?? "") != "") {
        try {
          if (v.supports != null && v.supports != "") {
            v.supports = v.supports.split(",").map((e) => {
              return e[0];
            });
          }
        } catch (error) {
          toast.error(
            "Phụ cấp tại vị trí " + (index + 1) + "cần có id đầu ký tự"
          );
          return;
        }
      }

      if ((v.BHXH ?? "") != "") {
        v.BHXH = v.BHXH == "Có" ? 1 : 0;

        v.BHYT = v.BHXH;

        v.BHTN = v.BHXH;
      }

      if ((v.married ?? "") != "") {
        v.married = v.married == "Có" ? 1 : 0;
      }

      if ((v.sex ?? "") != "") {
        v.sex = v.sex == "Nam" ? 1 : v.sex == "Nữ" ? 2 : null;
      }
    } else {
      if ((v.staff_code ?? "") == "") {
        toast.error("Mã nhân viên không hợp lệ tại vị trí" + (index + 1));
        return;
      }
      if ((v.name ?? "") == "") {
        toast.error("Tên không hợp lệ tại vị trí" + (index + 1));
        return;
      }
      if (v.phone_number.length !== 10) {
        toast.error("Số điện thoại tại vị trí " + (index + 1) + " không đúng");
        return;
      }
      if (v.start_join_time == null || v.start_join_time == "") {
        toast.error("Ngày tham gia tại vị trí " + (index + 1) + " không đúng");
        return;
      } else {
        v.start_join_time = moment(v.start_join_time, "DD-MM-YYYY").format(
          "YYYY-MM-DD HH:mm:ss"
        );
      }
      if (v.sex == null || v.sex == "") {
        toast.error("Giới tính tại vị trí " + (index + 1) + " không đúng");
        return;
      }
      if ((v.department_id ?? "") == "") {
        toast.error("Phòng ban tại vị trí " + (index + 1) + " không đúng");
        return;
      } else {
        v.department_id = departments.find(
          (e) => e.name == v.department_id
        )?.id;
      }
      if ((v.area_id ?? "") == "") {
        toast.error("Chi nhánh tại vị trí " + (index + 1) + " không đúng");
        return;
      } else {
        v.area_id = areas.find((e) => e.name == v.area_id)?.id;
      }
      if ((v.role_id ?? "") == "") {
        toast.error("Phân quyền tại vị trí " + (index + 1) + " không đúng");
        return;
      } else {
        v.role_id = roles.find((e) => e.name == v.role_id)?.id;
      }
      if (v.birthday == null || v.birthday == "") {
        toast.error("Ngày sinh tại vị trí " + (index + 1) + " không đúng");
        return;
      } else {
        v.birthday = moment(v.birthday, "DD-MM-YYYY").format("YYYY-MM-DD");
      }

      if ((v.sex ?? "") != "") {
        v.sex = v.sex == "Nam" ? 1 : v.sex == "Nữ" ? 2 : null;
      }

      /// check hợp đồng

      if ((v.type_key ?? "") == "") {
        toast.error("Loại hợp đồng tại vị trí " + (index + 1) + " không đúng");
        return;
      }
      if (v.start == null || v.start == "") {
        toast.error("Ngày bắt đầu tại vị trí " + (index + 1) + " không đúng");
        return;
      } else {
        v.start = moment(v.start, "DD-MM-YYYY").format("YYYY-MM-DD");
      }
      if (v.end == null || v.end == "") {
        toast.error("Ngày kết thúc tại vị trí " + (index + 1) + " không đúng");
        return;
      } else {
        v.end = moment(v.end, "DD-MM-YYYY").format("YYYY-MM-DD");
      }
      if (v.type_key == "part_time") {
        if (v.salary == null || v.salary == "") {
          toast.error(
            "Lương theo giờ tại vị trí " + (index + 1) + " không đúng"
          );
          return;
        } else {
          v.salary = v.salary
            .toString()
            .replaceAll(".", "")
            .replaceAll(",", ".");
        }
      } else {
        if (v.total_salary == null || v.total_salary == "") {
          toast.error("Tổng lương tại vị trí " + (index + 1) + " không đúng");
          return;
        } else {
          v.total_salary = v.total_salary
            .toString()
            .replaceAll(".", "")
            .replaceAll(",", ".");
        }
      }

      if (v.tax == null || v.tax == "") {
        toast.error("Thuế TNCN tại vị trí " + (index + 1) + " không đúng");
        return;
      }

      v.BHXH = v.BHXH == "Có" ? 1 : 0;

      v.BHYT = v.BHXH;

      v.BHTN = v.BHXH;

      v.married = v.married == "Có" ? 1 : 0;

      /// xử lý phụ cấp

      if ((v.supports ?? "") != "") {
        try {
          if (v.supports != null && v.supports != "") {
            v.supports = v.supports.split(",").map((e) => {
              return e[0];
            });
          }
        } catch (error) {
          toast.error(
            "Phụ cấp tại vị trí " + (index + 1) + "cần có id đầu ký tự"
          );
          return;
        }
      }
    }
  } catch (error) {
    toast.error("Dữ liệu tại vị trí " + (index + 1) + " không hợp lệ");
    return;
  }

  return v;
}

/// master data

function exportMasterDataToExcel(staffs, onDone) {
  var row = []; // là 1 hàng trên excel

  var header = findRowTitle(staffs).item; // hàng đầu tiên của file excel
  var max = findRowTitle(staffs).max; // max số lịch sử hợp đồng
  var maxS = findRowTitle(staffs).maxS; // max số lịch sử lương

  row.push([
    ...Array.from({ length: 22 }, () => "THÔNG TIN CÁ NHÂN"),
    ...Array.from({ length: 4 + max * 4 }, () => "HỢP ĐỒNG LAO ĐỘNG"),
    ...Array.from({ length: 11 + maxS * 11 }, () => "QUÁ TRÌNH LƯƠNG"),
    ...Array.from({ length: 3 }, () => "NGÂN HÀNG"),
    ...Array.from({ length: 2 }, () => "THUẾ"),
    ...Array.from({ length: 2 }, () => "THÔNG TIN NGHỈ"),
    ...Array.from({ length: 2 }, () => "BHXH"),
  ]);

  console.log(row);

  row.push(header);

  staffs.forEach((s) => {
    row.push(findRowData(s, max, maxS));
  });


  console.log(row);

  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    const sheet1 = workbook.sheet(0);
    sheet1.cell("A1").value(row);
    const range = sheet1.usedRange();
    range.style("border", true);
    range.style("horizontalAlignment", "left");

    console.log(`A1:${convertNumberToColumnName(22)}1`);
    console.log(
      `${convertNumberToColumnName(23)}1:${convertNumberToColumnName(
        23 + 4 + max * 4
      )}1`
    );

    sheet1.range(`A1:${convertNumberToColumnName(22)}1`).merged(true)
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      fontSize: 20,
      fill: "DCECD2",
      bold: true,
    });

    sheet1
      .range(
        `${convertNumberToColumnName(23)}1:${convertNumberToColumnName(
          22 + 4 + max * 4
        )}1`
      )
      .merged(true)
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      fontSize: 20,
      fill: "FDF0C1",
      bold: true,
    });

    sheet1
      .range(
        `${convertNumberToColumnName(
          23 + 4 + max * 4
        )}1:${convertNumberToColumnName(22 + 4 + max * 4 + 11 + maxS * 11)}1`
      )
      .merged(true)
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      fontSize: 20,
      fill: "A5B9E0",
      bold: true,
    });

    sheet1
      .range(
        `${convertNumberToColumnName(
          23 + 4 + max * 4 + 11 + maxS * 11
        )}1:${convertNumberToColumnName(
          22 + 4 + max * 4 + 11 + maxS * 11 + 3
        )}1`
      )
      .merged(true)
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      fontSize: 20,
      fill: "F5BF9D",
      bold: true,
    });

    sheet1
      .range(
        `${convertNumberToColumnName(
          23 + 4 + max * 4 + 11 + maxS * 11 + 3
        )}1:${convertNumberToColumnName(
          22 + 4 + max * 4 + 11 + maxS * 11 + 3 + 2
        )}1`
      )
      .merged(true)
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      fontSize: 20,
      fill: "60A137",
      bold: true,
    });

    sheet1
      .range(
        `${convertNumberToColumnName(
          23 + 4 + max * 4 + 11 + maxS * 11 + 3 + 2
        )}1:${convertNumberToColumnName(
          22 + 4 + max * 4 + 11 + maxS * 11 + 3 + 2 + 2
        )}1`
      )
      .merged(true)
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      fontSize: 20,
      fill: "EFA071",
      bold: true,
    });

    sheet1
      .range(
        `${convertNumberToColumnName(
          23 + 4 + max * 4 + 11 + maxS * 11 + 3 + 2 + 2
        )}1:${convertNumberToColumnName(
          22 + 4 + max * 4 + 11 + maxS * 11 + 3 + 2 + 2 + 2
        )}1`
      )
      .merged(true)
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      fontSize: 20,
      fill: "FEF3ED",
      bold: true,
    });

    sheet1.row(1).height(50);
    sheet1.row(2).height(50);

    // set style cho row 2

    sheet1.range(`A2:${convertNumberToColumnName(22)}2`).style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      fontSize: 13,
      fill: "DCECD2",
      bold: true,
    });

    sheet1
      .range(
        `${convertNumberToColumnName(23)}2:${convertNumberToColumnName(
          22 + 4 + max * 4
        )}2`
      )
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontSize: 13,
        fill: "FDF0C1",
        bold: true,
      });

    sheet1
      .range(
        `${convertNumberToColumnName(
          23 + 4 + max * 4
        )}2:${convertNumberToColumnName(22 + 4 + max * 4 + 11 + maxS * 11)}2`
      )
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontSize: 13,
        fill: "A5B9E0",
        bold: true,
      });

    sheet1
      .range(
        `${convertNumberToColumnName(
          23 + 4 + max * 4 + 11 + maxS * 11
        )}2:${convertNumberToColumnName(
          22 + 4 + max * 4 + 11 + maxS * 11 + 3
        )}2`
      )
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontSize: 13,
        fill: "F5BF9D",
        bold: true,
      });

    sheet1
      .range(
        `${convertNumberToColumnName(
          23 + 4 + max * 4 + 11 + maxS * 11 + 3
        )}2:${convertNumberToColumnName(
          22 + 4 + max * 4 + 11 + maxS * 11 + 3 + 2
        )}2`
      )
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontSize: 13,
        fill: "60A137",
        bold: true,
      });

    sheet1
      .range(
        `${convertNumberToColumnName(
          23 + 4 + max * 4 + 11 + maxS * 11 + 3 + 2
        )}2:${convertNumberToColumnName(
          22 + 4 + max * 4 + 11 + maxS * 11 + 3 + 2 + 2
        )}2`
      )
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontSize: 13,
        fill: "EFA071",
        bold: true,
      });

    sheet1
      .range(
        `${convertNumberToColumnName(
          23 + 4 + max * 4 + 11 + maxS * 11 + 3 + 2 + 2
        )}2:${convertNumberToColumnName(
          22 + 4 + max * 4 + 11 + maxS * 11 + 3 + 2 + 2 + 2
        )}2`
      )
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontSize: 13,
        fill: "FEF3ED",
        bold: true,
      });

    for (let i = 1; i <= 46 + max * 4 + maxS * 11; i++) {
      const column = sheet1.column(i);
      column.style({ wrapText: true });
      if (i <= 22) {
        column.width(15);
        continue;
      }
      column.width(20); // set width for each column
    }
    return workbook.outputAsync().then((res) => {
      saveAs(res, "MasterData.xlsx");
      // onDone();
    });
  });
}

const findRowTitle = (staffs) => {
  var item = [];

  for (const key in mapMaster) {
    item.push(mapMaster[key]);
  }

  /// tìm tổng số cột hợp đồng lao động

  var max = 0;
  staffs.forEach((e) => {
    if (e.contract.history_contract_type_0.length > max) {
      max = e.contract.history_contract_type_0.length;
    }
  });

  item.push("Loại hợp đồng (Hiện tại)");
  item.push("Số HĐ (Hiện tại)");
  item.push("Ngày hiện tại (Hiện tại)");
  item.push("Ngày kết thúc (Hiện tại)");

  for (let i = 0; i < max; i++) {
    item.push("Loại hợp đồng (Lần " + (i + 1) + ")");
    item.push("Số HĐ (Lần " + (i + 1) + ")");
    item.push("Ngày hiện tại (Lần " + (i + 1) + ")");
    item.push("Ngày kết thúc (Lần " + (i + 1) + ")");
  }

  /// tìm tổng số cột quá trình lương

  var maxS = 0;
  staffs.forEach((e) => {
    console.log(
      "xxxxxxxxxxxxxxxxxx>",
      e.contract.history_contract_type_1.length
    );
    if (e.contract.history_contract_type_1.length > maxS) {
      maxS = e.contract.history_contract_type_1.length;
    }
  });

  console.log("=========================>", maxS);

  item.push("Tổng mức lương (Hiện tại)");
  item.push("Lương vị trí (Hiện tại)");
  item.push("Phụ cấp ăn trưa (Hiện tại)");
  item.push("Phụ cấp xăng xe (Hiện tại)");
  item.push("Phụ cấp điện thoại (Hiện tại)");
  item.push("Phụ cấp thâm niên (Hiện tại)");
  item.push("Tổng phụ cấp thêm (Hiện tại)");
  item.push("Phụ cấp thêm (Hiện tại)");
  item.push("P2 (Hiện tại)");
  item.push("P3 (Hiện tại)");
  item.push("Ngày hiệu lực (Hiện tại)");

  for (let i = 0; i < maxS; i++) {
    item.push("Tổng mức lương (Lần " + (i + 1) + ")");
    item.push("Lương vị trí (Lần " + (i + 1) + ")");
    item.push("Phụ cấp ăn trưa (Lần " + (i + 1) + ")");
    item.push("Phụ cấp xăng xe (Lần " + (i + 1) + ")");
    item.push("Phụ cấp điện thoại (Lần " + (i + 1) + ")");
    item.push("Phụ cấp thâm niên (Lần " + (i + 1) + ")");
    item.push("Tổng phụ cấp thêm (Lần " + (i + 1) + ")");
    item.push("Phụ cấp thêm (Lần " + (i + 1) + ")");
    item.push("P2 (Lần " + (i + 1) + ")");
    item.push("P3 (Lần " + (i + 1) + ")");
    item.push("Ngày hiệu lực (Lần " + (i + 1) + ")");
  }

  item.push("Số tài khoản ngân hàng");
  item.push("Tên ngân hàng");
  item.push("Chi nhánh");

  item.push("Mã số thuế");
  item.push("Số người phụ thuộc");

  item.push("Ngày nghỉ việc");
  item.push("Lý do nghỉ việc");

  item.push("Số sổ BHXH");
  item.push("Nơi đăng ký KCB ban đầu");

  return { item: item, max: max, maxS: maxS };
};

const findRowData = (staff, max, maxS) => {
  var item = [];

  for (const key in mapMaster) {
    item.push(handleData(staff[key], key));
  }

  /// tìm tổng số cột hợp đồng lao động

  item.push(staff.contract?.type_label ?? "");
  item.push(staff.contract?.id ?? "");
  if (
    staff.contract?.start &&
    moment(staff.contract?.start).format("DD-MM-YYYY") != "Invalid date"
  ) {
    item.push(moment(staff.contract?.start).format("DD-MM-YYYY"));
  } else {
    item.push("");
  }
  // check invalid date
  if (
    staff.contract?.end &&
    moment(staff.contract?.end).format("DD-MM-YYYY") != "Invalid date"
  ) {
    item.push(moment(staff.contract?.end).format("DD-MM-YYYY"));
  } else {
    item.push("");
  }

  for (let i = 0; i < max; i++) {
    if (i < staff.contract?.history_contract_type_0.length) {
      item.push(staff.contract?.history_contract_type_0[i]?.type_label ?? "");
      item.push(staff.contract?.history_contract_type_0[i]?.id ?? "");
      if (staff.contract?.history_contract_type_0[i]?.start) {
        item.push(
          moment(staff.contract?.history_contract_type_0[i]?.start).format(
            "DD-MM-YYYY"
          )
        );
      } else {
        item.push("");
      }
      if (staff.contract?.history_contract_type_0[i]?.end) {
        item.push(
          moment(staff.contract?.history_contract_type_0[i]?.end).format(
            "DD-MM-YYYY"
          )
        );
      } else {
        item.push("");
      }
    } else {
      item.push("");
      item.push("");
      item.push("");
      item.push("");
    }
  }

  /// tìm tổng số cột quá trình lương

  item.push(formatMoneyVND(staff.contract?.total_salary ?? 0, true)); // tổng mức lương
  item.push(formatMoneyVND(staff.contract?.position_salary ?? 0, true)); // lương vị trí
  item.push(formatMoneyVND(staff.contract?.pc_food ?? 0, true)); // ăn trưa
  item.push(formatMoneyVND(staff.contract?.pc_move ?? 0, true)); // xăng xe
  item.push(formatMoneyVND(staff.contract?.pc_phone ?? 0, true)); // điện thoại
  item.push(staff.contract?.seniority_percent ?? ""); // thâm niên
  item.push(
    formatMoneyVND(
      (staff.contract?.supports ?? [])
        .map((e) => e.value)
        .reduce((x, c) => x + c, 0),
      true
    )
  ); // tổng phụ cấp thêm
  item.push(
    (staff.contract?.supports ?? []).map((e) => e.title || "").join(", ")
  ); // phụ cấp thêm
  item.push(formatMoneyVND(staff.contract?.support_productive ?? 0, true)); // p2
  item.push(formatMoneyVND(staff.contract?.support_result ?? 0, true)); // p3

  if (
    staff.contract?.updated_at &&
    moment(staff.contract?.updated_at).format("DD-MM-YYYY") != "Invalid date"
  ) {
    item.push(moment(staff.contract?.updated_at).format("DD-MM-YYYY"));
  } else {
    item.push("");
  }
  // ngày hiệu lực

  for (let i = 0; i < maxS; i++) {
    if (i < staff.contract?.history_contract_type_1.length) {
      item.push(
        formatMoneyVND(
          staff.contract?.history_contract_type_1[i]?.total_salary ?? 0,
          true
        )
      );
      item.push(
        formatMoneyVND(
          staff.contract?.history_contract_type_1[i]?.position_salary ?? 0,
          true
        )
      );
      item.push(
        formatMoneyVND(
          staff.contract?.history_contract_type_1[i].pc_food ?? 0,
          true
        )
      );
      item.push(
        formatMoneyVND(
          staff.contract?.history_contract_type_1[i].pc_move ?? 0,
          true
        )
      );
      item.push(
        formatMoneyVND(
          staff.contract?.history_contract_type_1[i].pc_phone ?? 0,
          true
        )
      );
      item.push(
        staff.contract?.history_contract_type_1[i]?.seniority_percent ?? ""
      );
      item.push(
        formatMoneyVND(
          (staff.contract?.history_contract_type_1[i]?.supports ?? [])
            .map((e) => e.value)
            .reduce((x, c) => x + c, 0),
          true
        )
      );
      item.push(
        (staff.contract?.history_contract_type_1[i]?.supports ?? [])
          .map((e) => e.title || "")
          .join(", ")
      );
      item.push(
        formatMoneyVND(
          staff.contract?.history_contract_type_1[i].support_productive ?? 0,
          true
        )
      );
      item.push(
        formatMoneyVND(
          staff.contract?.history_contract_type_1[i].support_result ?? 0,
          true
        )
      );
      if (
        staff.contract?.history_contract_type_1[i]?.updated_at &&
        moment(staff.contract?.history_contract_type_1[i]?.updated_at).format(
          "DD-MM-YYYY"
        ) != "Invalid date"
      ) {
        item.push(
          moment(staff.contract?.history_contract_type_1[i]?.updated_at).format(
            "DD-MM-YYYY"
          )
        );
      } else {
        item.push("");
      }
    } else {
      item.push("");
      item.push("");
      item.push("");
      item.push("");
      item.push("");
      item.push("");
      item.push("");
      item.push("");
      item.push("");
      item.push("");
      item.push("");
    }
  }

  item.push(staff.bank_number ?? "");
  item.push(staff.bank_name ?? "");
  item.push(staff.bank_branch ?? "");

  item.push(staff.tax_code ?? "");
  item.push(staff.contract?.dependent_count ?? "");

  if (
    staff.date_leave != null &&
    moment(staff.contract?.date_leave).format("DD-MM-YYYY") != "Invalid date"
  ) {
    item.push(moment(staff.date_leave).format("DD-MM-YYYY"));
  } else {
    item.push("");
  }
  item.push(staff.leave_reason ?? "");

  item.push(staff.bhyt_code ?? "");
  item.push(staff.healthcare_first_place ?? "");

  return item;
};

const mapMaster = {
  staff_code: "Mã nhân viên",
  name: "Họ và tên",
  department_name: "Phòng ban",
  area_name: "Chi nhánh",
  position: "Vị trí",
  start_join_time: "Ngày vào làm",
  seniority: "Thâm niên",
  birthday: "Ngày sinh",
  sex: "Giới tính",
  cmnd: "Số CMND/CCCD",
  date_range: "Ngày cấp",
  issued_by: "Nơi cấp",
  permanent_address: "Địa chỉ thường trú",
  temporary_address: "Địa chỉ tạm trú",
  nation: "Dân tộc",
  married: "Tình trạng hôn nhân",
  religion: "Tôn giáo",
  level: "Trình độ học vấn",
  specialized: "Chuyên ngành",
  school: "Trường học",
  phone_number: "Số điện thoại",
  email: "Email",
  // type_label: "Loại hợp đồng (Hiện tại)",
  // id: "Số HĐ (Hiện tại)",
  // start: "Ngày hiện tại (Hiện tại)",
  // end: "Ngày kết thúc (Hiện tại)",
};

export {
  exportStaffExcel,
  readFileStaff,
  handleDataImport,
  exportMasterDataToExcel,
};
