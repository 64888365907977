import { constants as c } from "../constants";
const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));

const requestOptions = ({ method, bodyData }) => {
  let options = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo || "",
    },
  };
  bodyData && (options.body = JSON.stringify(bodyData));

  return options;
};

/* ------------------ WorkShift Api ----------------------------------- */
async function fetchAreas() {
  return fetch(`${c.API_URL}/areas`, requestOptions({ method: "GET" }))
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function fetchRatingAreas() {
  return fetch(`${c.API_URL}/review/rating`, requestOptions({ method: "GET" }))
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function fetchReportReviewAreas(areaId) {
  return fetch(
    `${c.API_URL}/review/report?area_id=${areaId}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function fetchHistoryReviewAreas(areaId) {
  return fetch(
    `${c.API_URL}/review/history?area_id=${areaId}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function getAreaById(id = "") {
  return fetch(`${c.API_URL}/areas/${id}`, requestOptions({ method: "GET" }))
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function updateArea(id, data) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
    body: JSON.stringify(data),
  };
  return fetch(`${c.API_URL}/areas/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function getHistoryLogArea(param) {
  return fetch(
    `${c.API_URL}/history-logs/${param}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function getFormRepairArea(param) {
  return fetch(
    `${c.API_URL}/procedure/manager/form/${param}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function addHistoryLogArea(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
    body: JSON.stringify(data),
  };

  return fetch(`${c.API_URL}/history-logs`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function deleteHistoryLogArea(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };

  return fetch(`${c.API_URL}/history-logs/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function deleteQcHistory(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };

  return fetch(`${c.API_URL}/qc/qc-evaluate-submit/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function getQcHistory(param) {
  return fetch(
    `${c.API_URL}/qc/qc-evaluate-submit${param}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

export const areaServices = {
  fetchAreas,
  fetchRatingAreas,
  addHistoryLogArea,
  getHistoryLogArea,
  updateArea,
  getFormRepairArea,
  fetchReportReviewAreas,
  deleteHistoryLogArea,
  deleteQcHistory,
  fetchHistoryReviewAreas,
  getAreaById,
  getQcHistory,
};
